<template flex>
  <div class="dashboard">
    <v-app>
      <!-- Sizes your content based upon application components -->
      <div class="inner-container">
        <div class="top-page-header">
          <div class="head-title">
            <h1>{{ $t('pages.automationRule') }}</h1>
          </div>
          <v-tabs v-model="tab" background-color="transparent" class="custom-tab fixed-tab">
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </div>

        <!-- Provides the application the proper gutter -->
        <v-container fluid class="v-main-content stepper-template">
          <!-- If using vue-router -->
          <!-- <router-view></router-view> -->
          <template>
            <div>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card flat elevation="3" class="pa-4 rounded-lg">
                    <v-card-text class="pa-0 v-flex-right">

                      <div class="v-search-with-btn">

                        <v-text-field
                          v-model="searchAR"
                          append-icon="mdi-magnify"
                          label="Type here to filter below items"
                          hide-details
                        ></v-text-field>
                        <v-btn
                          depressed
                          class="rounded-lg ml-3"
                          color="primary"
                          @click="callCreateRule">
                          {{ $t('aRules.createAR') }}
                        </v-btn>
                        <CreateRule
                          @fromCreateRule="dataFromCreateRule($event)"
                          :fromRulesPage="dataToCreateRule"
                        ></CreateRule>
                      </div>
                    </v-card-text>
                  </v-card>
                  <v-card flat>
                    <v-card-text>
                      <v-row style="display: block">
                        <v-data-table
                          :search="searchAR"
                          v-model="selectedARItems"
                          :headers="arHeaders"
                          :items="arItems"
                          :items-per-page="10"
                          class="elevation-0 mt-4"
                          item-key="_id"
                          :loading="arLoading"
                          loading-text="Loading... Please wait"
                          fixed-header
                          no-data-text="No Data to Show"
                          no-results-text="No Data to Show"
                        >
                          <template v-slot:[`item.sno`]="{ index }">
                                {{index + 1}}
                          </template>
                          <template v-slot:item.actions="{ item }">
                                <v-icon
                                  small
                                  class="mr-2"
                                  color="primary"
                                  @click="editARRule(item)"
                                  dark
                                >
                                  mdi-pencil
                                </v-icon>
                                <v-icon
                                  small
                                  class="mr-2"
                                  color="error"
                                  @click="deleteARRule(item._id)"
                                  dark
                                >
                                  mdi-delete
                                </v-icon>
                          </template>
                          <template v-slot:item.disable="{ item }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  v-on="on"
                                  v-bind="attrs"
                                  style="width: min-content"
                                >
                                  <v-switch
                                    v-if="item.arStatus == 'RUNNING'"
                                    v-model="item.arStatus"
                                    color="success"
                                    true-value="RUNNING"
                                    false-value="DISABLED"
                                    @change="disableARRule(item._id)"
                                  ></v-switch>
                                  <v-switch
                                    v-if="item.arStatus == 'DISABLED'"
                                    v-model="item.arStatus"
                                    color="success"
                                    true-value="RUNNING"
                                    false-value="DISABLED"
                                    @change="enableARRule(item._id)"
                                  ></v-switch>
                                </div>
                              </template>
                              <span v-if="item.arStatus == 'RUNNING'">{{
                                $t('aRules.disable')
                              }}</span>
                              <span v-if="item.arStatus == 'DISABLED'">{{
                                $t('aRules.enable')
                              }}</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat elevation="3" class="pa-4 rounded-lg">
                    <v-card-text class="pa-0 v-flex-right">
                      <div class="v-search-with-btn">
                        <v-text-field
                            v-model="searchLogs"
                            append-icon="mdi-magnify"
                            label="Type here to filter below items"
                            single-line
                            hide-details
                          ></v-text-field>
                      </div>
                    </v-card-text>

                      <v-row style="display: block">
                        <v-data-table
                          :search="searchLogs"
                          v-model="selectedARlogs"
                          :headers="logHeaders"
                          :items="logItems"
                          :items-per-page="10"
                          class="elevation-0 mt-4"
                          item-key="_id"
                          :loading="logLoading"
                          loading-text="Loading... Please wait"
                          fixed-header
                          no-data-text="No Data to Show"
                          no-results-text="No Data to Show"
                        >
                          <template v-slot:[`item.sno`]="{ index }">
                                {{index + 1}}
                          </template>
                          <!-- <template v-slot:item.actions="{ item }">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      small
                                      class="mr-2"
                                      color="error"
                                      @click="deleteARLog(item.logId)"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </template>
                                  <span>Delete</span>
                                </v-tooltip>
                              </template> -->
                        </v-data-table>
                      </v-row>

                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
            <DecisionAlert
              :DecisionAlert="decisionAlertFlag"
              :DecisionAlertTitle="decisionAlertTitle"
              :DecisionAlertMessage="decisionAlertMessage"
              :DecisionAlertData="decisionAlertData"
              @emitDecisionAlertCancelClicked="clearDecisionModel"
              @emitDecisionAlertOkClicked="decisionOkAction($event)"
            />
          </template>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<script>
import {
  GET_ALL_RULES,
  GET_ALL_LOGS,
  DISABLE_AR_RULE,
  DELETE_AR_RULE,
  ENABLE_AR_RULE,
} from '../utils/arrules-queries';
import CreateRule from '../components/arrules/createrule.vue';
import DecisionAlert from '../components/DecisionAlert';
import { updateCLF } from '../utils/util'

export default {
  name: 'Rules',
  components: {
    CreateRule,
    DecisionAlert,
  },
  data() {
    return {
      decisionAlertFlag: false,
      decisionAlertTitle: 'Are you sure ?',
      decisionAlertMessage: '',
      decisionAlertData: {},
      tab: null,
      searchAR: '',
      searchLogs: '',
      selectedARlogs: [],
      logItems: [],
      logLoading: false,
      disableSwitch: null,
      selectedARItems: [],
      arLoading: false,
      arSearch: '',
      dataToCreateRule: {},
      fullscreenLoader: null,
      arItems: []
    };
  },
  computed: {
    logHeaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('aRules.sno'), value: 'sno' },
          { text: this.$t('aRules.name'), value: 'ruleName' },
          // {
          //   text: this.$t('aRules.createdAt'),
          //   value: 'createdOn',
          //   default: 'NA',
          // },
          { text: this.$t('aRules.status'), value: 'logStatus', default: 'NA' },
          {
            text: this.$t('aRules.completed'),
            value: 'updatedOn',
            default: 'NA',
          },
          {
            text: this.$t('aRules.actionFreq'),
            value: 'ruleFreq',
            default: 'NA',
          },
          // { text: 'Actions', value: 'actions' },
        ];
      },
    },

    items: {
      cache: false,
      get() {
        return [this.$t('aRules.rules'), this.$t('aRules.logs')];
      },
    },

    arHeaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('aRules.sno'), value: 'sno' },
          { text: this.$t('aRules.name'), value: 'ruleName' },
          {
            text: this.$t('aRules.createdFor'),
            value: 'arFor.forType',
            default: 'NA',
          },
          { text: this.$t('aRules.status'), value: 'arStatus', default: 'NA' },
          {
            text: this.$t('aRules.chckFreq'),
            value: 'arSettings.checkFrequency',
            default: 'NA',
          },
          {
            text: this.$t('aRules.actionFreq'),
            value: 'arActions.actionLimit',
            default: 'NA',
          },
          { text: this.$t('aRules.enableDisable'), value: 'disable', sortable: false },
          { text: this.$t('aRules.actions'), value: 'actions', sortable: false },
        ];
      },
    },

    // arItems: {
    //   cache: false,
    //   get() {
    //     return [
    //       {
    //         name: 'Rule 1',
    //         createdOn: 'Date on',
    //         action: 'Email Notification',
    //         status: 'Running',
    //         checkFreq: 'Every 3 Hrs',
    //         actionFreq: 'Every 12 Hrs',
    //       },
    //     ];
    //   },
    // },
  },
  methods: {
    clearDecisionModel() {
      this.decisionAlertFlag = false;
    },
    decisionOkAction(event) {
      console.log(event);
      this.decisionAlertFlag = false;
      const id = event.id;
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_AR_RULE,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.arItems = this.arItems.filter((x) => x._id != id);
          this.fullscreenLoader.hide();
          this.$notify.success('Automation rule deleted successfully');
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(
            'Unable to process your request. Please try later'
          );
        });
    },
    getAllRules() {
      this.arLoading = true;
      this.arItems = [];

      this.$apollo
        .query({
          query: GET_ALL_RULES,
          variables: {
            teamId: 'none',
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          //console.log(res.data);
          if (
            res.data.getAllRules == null ||
            res.data.getAllRules == undefined
          ) {
            //console.log('No data');
            return;
          }
          this.arItems = res.data.getAllRules;
          this.arLoading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.arLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    getAllLogs() {
      this.logLoading = true;
      this.logItems = [];

      this.$apollo
        .query({
          query: GET_ALL_LOGS,
          variables: {
            teamId: 'none',
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          //console.log(res.data);
          if (res.data.getAlllogs == null || res.data.getAlllogs == undefined) {
            //console.log('No data');
            return;
          }
          this.logItems = res.data.getAlllogs;
          this.logItems = this.logItems.map(e => {
            const o = {
              createdOn : e.createdOn.toString().substr(0,10),
              logId: e.logId,
              logStatus: e.logStatus,
              ruleFreq: e.ruleFreq,
              ruleId: e.ruleId,
              ruleName: e.ruleName,
              updatedOn: 'On ' +e.updatedOn.toString().substr(0,10) + ' At ' + e.updatedOn.toString().substr(11,5),
              _id: e._id
            }
            return o
          })
          //console.log(this.logItems)
          this.logLoading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.logLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    dataFromCreateRule(e) {
      //console.log(e)
      this.fullscreenLoader = this.$loading.show();
      if (e) {
        this.getAllRules();
        updateCLF('createRule', this)
      }
      this.fullscreenLoader.hide();
    },
    callCreateRule() {
      this.dataToCreateRule = {
        mode: 1,
        now: Date.now(),
      };
    },
    editARRule(item) {
      //console.log('editARRule',item);
      this.dataToCreateRule = {
        mode: 2,
        now: Date.now(),
        item: item,
      };
    },
    deleteARRule(id) {
      this.decisionAlertFlag = true;
      this.decisionAlertData = {
        id: id,
        from: 'deleteRule',
        now: Date.now(),
      };
    },
    viewARRule(item) {
      console.log('viewARRule');
    },
    disableARRule(id) {
      //console.log(id)
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DISABLE_AR_RULE,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success('Automation rule disabled successfully');
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(
            'Unable to process your request. Please try later'
          );
        });
    },
    enableARRule(id) {
      //console.log(id)
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: ENABLE_AR_RULE,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success('Automation rule enabled successfully');
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(
            'Unable to process your request. Please try later'
          );
        });
    },
    deleteARLog(id) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_AR_LOG,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
        });
    },
  },
  mounted() {
    this.getAllRules();
    this.getAllLogs();
    updateCLF('automationRules', this)
  },
};
</script>
