





























































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash';
import Vue from 'vue';
import { APPS_WITH_ORG } from '../../utils/apollo-queries';

export default Vue.extend({
  name: 'SelectApps',
  props: {
    selectApps: {
      // this object also includes now property which is current timestamp.
      // this is to trigger change detection even if values are unchanged in the passed object.
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  computed: {
    a: function (): string {
      return 'a';
    },
  },

  watch: {
    selectApps: function (data) {
      this.show = true;
      this.fullscreenLoader = this.$loading.show();
    },
  },

  data() {
    return {
      show: false,
      selectedApps: [],
      totalAccounts: 0,
      totalApps: 0,
      totalSelectedApps: 0,
      //temporarily added to remove errors
      valid: false,
      none: '',
      panel: [],
      selectedAccount: null,
      appsWithOrg: [],
      fullscreenLoader: null,
    };
  },

  methods: {
    oncheck(): void {
        this.totalSelectedApps = this.selectedApps.length
    },

    clearAll() {
      this.selectedApps = [];
      this.totalSelectedApps = 0;
    },

    save() {
      //console.log(this.selectedApps);
      this.$emit('onAppSelection', this.selectedApps);
      this.close();
    },

    close() {
      this.show = false;
      this.selectedApps = [];
      this.totalSelectedApps = 0;
      this.totalApps = 0;
    },

  },
  apollo: {
    getAppsWithOrg: {
      query: APPS_WITH_ORG,
      skip() {
        return !this.show;
      },
      update(data) {
          this.appsWithOrg = data.getAppsWithOrg;
          this.totalApps = this.appsWithOrg.length;
          this.fullscreenLoader.hide();
      },
      error (error) {
        //console.log(error)
        this.fullscreenLoader.hide();
      },
    },
  },
});
