


































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash';
import { APPSLIST_WITH_CAMPAIGNS } from '../../utils/apollo-queries';
import Vue from 'vue';
import { AdGroupSelectorContext } from '../../utils/enums';

export default Vue.extend({
  name: 'SelectKeywords',
  props: {
    selectKeywordData: {
      // this object also includes now property which is current timestamp.
      // this is to trigger change detection even if values are unchanged in the passed object.
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  computed: {
    a: function (): string {
      return 'a';
    },
  },

  watch: {
    selectKeywordData: function (data) {
      //console.log(data);
      this.show = true;
      this.fullscreenLoader = this.$loading.show();
    },
  },

  data() {
    return {
      totalSelectedGroups: 0,
      show: false,
      appsListWithCampaigns: null,
      selectedCampaign: null,
      totalCampaigns: 0,
      totalListedGroups: 0,
      onlyOneAdgroupSnack: false,

      selectedAdgroup: null,
      totalListedKeywords: 0,
      totalSelectedKeywords: 0,

      //temporarily added to remove errors
      valid: false,
      none: '',
      panel: [],
      selKeys: [],
      fullscreenLoader: null,
    };
  },

  methods: {
    deleteKeyword(d){
      this.appsListWithCampaigns.map((app) =>
        app.campaigns.map((campaign) =>
          campaign.adgroups.map((group) =>
            group.keywords.map((keyword) => {
              if(keyword.id === d.id) keyword.selected = false
            })
          )
        )
      );
      this.oncheck(d)
    },

    listAdgroups(campaign) {
      this.selectedCampaign = campaign;

      this.totalListedGroups = this.selectedCampaign.adgroups.length;
    },

    listKeywords(adgroup) {
      this.selectedAdgroup = adgroup;
      this.totalListedKeywords = this.selectedAdgroup.keywords.length;
    },

    oncheck(keyword): void {
      // summing up all selected groups
      // this.totalSelectedGroups = [].concat(...this.appsListWithCampaigns.map(app => app.campaigns.map(campaign => campaign.adgroups.reduce((previous, current) => current.selected === true ? 1 : 0 + previous, 0)))).reduce((p, c) => p + c);

      const toEmit = _.cloneDeep(this.appsListWithCampaigns);
      toEmit.forEach((app) =>
        app.campaigns.forEach((campaign) =>
          campaign.adgroups.forEach(
            (adgroup) =>
              (adgroup.keywords = adgroup.keywords
                .filter((keyword) => keyword.selected)
                // need to omit property 'selected' since it's only used for binding in component's state
                .map((keyword) => _.omit(keyword, ['selected'])))
          )
        )
      );
      const keywords = this.getNested(toEmit, 'KEYWORD');
      this.totalSelectedKeywords = keywords?.length || 0;
      this.selKeys = keywords
    },

    getNested(appsWithCampaigns, level) {
      // returning nested data as requested (apps > campaigns > adgroups)
      return level === 'APP'
        ? appsWithCampaigns
        : [].concat(
            ...appsWithCampaigns.map((app) =>
              level === 'CAMPAIGN'
                ? app.campaigns
                : [].concat(
                    ...app.campaigns.map((campaign) =>
                      level === 'ADGROUP'
                        ? campaign.adgroups
                        : [].concat(
                            ...campaign.adgroups.map(
                              (adgroup) => adgroup.keywords
                            )
                          )
                    )
                  )
            )
          );
    },

    clearAll() {
      this.totalSelectedKeywords = 0;

      this.appsListWithCampaigns.map((app) =>
        app.campaigns.map((campaign) =>
          campaign.adgroups.map((group) =>
            group.keywords.map((keyword) => (keyword.selected = false))
          )
        )
      );
    },

    save() {
      const toEmit = _.cloneDeep(this.appsListWithCampaigns);
      toEmit.forEach((app) =>
        app.campaigns.forEach((campaign) =>
          campaign.adgroups.forEach(
            (adgroup) =>
              (adgroup.keywords = adgroup.keywords
                .filter((keyword) => keyword.selected)
                // need to omit property 'selected' since it's only used for binding in component's state
                .map((keyword) => _.omit(keyword, ['selected'])))
          )
        )
      );
      const selKeyIds = this.selKeys.map(x => x.id)
      const finalKeys = []
      toEmit.map(e => {
        e.campaigns.map(x => {
          x.adgroups.map(y => {
            y.keywords.map(z => {
              if(selKeyIds.includes(z.id)){
                const t = {
                  orgId : x.orgId,
                  campId: x.id,
                  id: z.id,
                  name: z.text
                }
                finalKeys.push(t)
              }
            })
          })
        })
      })
      this.$emit('onkeywordSelection', finalKeys);
      this.clearAll()
      this.close();
    },

    close() {
      this.show = false;
      this.appsListWithCampaigns = null;
      this.selectedCampaign = null;
      this.selectedAdgroup = null
      this.totalCampaigns = 0;
      this.totalSelectedKeywords = 0;
      this.totalListedKeywords = 0
      this.totalListedGroups = 0;
      this.keysFromKai = [];
    },

    expandAll() {
      this.panel = [...Array(this.appsListWithCampaigns).keys()].map(
        (k, i) => i
      );
    },

    collapseAll() {
      this.panel = [];
    },
  },
  apollo: {
    getAppsListWithCampaigns: {
      query: APPSLIST_WITH_CAMPAIGNS,
      skip() {
        return !this.show;
      },
      update({ getAppsListWithCampaigns }) {
        this.appsListWithCampaigns = _.cloneDeep(getAppsListWithCampaigns);
        this.totalCampaigns = this.appsListWithCampaigns.reduce(
          (p, c) => p + c.campaigns.length,
          0
        );
        this.fullscreenLoader.hide();
      },
    },
  },
});
