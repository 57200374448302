import gql from 'graphql-tag';

export const GET_ALL_RULES = gql`query getAllRules($teamId: String!, $now: String!){
  getAllRules:getRulesByTeamId(teamId:$teamId, now: $now){
    _id
    ruleId
    arStatus
    ruleName
    arFor {
      forType
      arFrom {
        fromType
        orgId
        fromValues{
          fromValName
          fromValId
        }
        campaignId
      }
    }
    arActions{
      actionLimit
      actionList{
        aiType
        aiOperation
        aiOperators
        aiAddTo
        aiCPTBidType
        aiCPTBidValue
        aiValue
        aiEmail
        aiSlack
        aiCampaigns{
          addToName
          addToId
        }
        aiAdgroups{
          addToName
          addToId
        }
        aiMatchType
      }
    }
    createdOn
    arConditions{
      ciField
      ciOperation
      ciOperator
      ciFromValue
      ciFromValue
      ciToValue
      ciValue
    }
    arConditionSettings{
      ciTimeRange
      ciCustomHour
      ciAndOr
    }
    arSettings{
      checkFrequency
      excludeDays
      customHour
    }
  }
}`;


export const GET_ALL_LOGS = gql`query getAlllogs($teamId: String!, $now: String!){
  getAlllogs:getLogsByTeamId(teamId:$teamId, now: $now){
    _id
    logId
    ruleName
    logStatus
    createdOn
    updatedOn
    ruleFreq
    logId
    ruleId
  }
}`;


export const DISABLE_AR_RULE = gql`query disableARRule($ruleId: String!){
  disableARRule: disableRule(ruleId:$ruleId){
    successMessage
    code
  }
}`;


export const ENABLE_AR_RULE = gql`query enableARRule($ruleId: String!){
  enableARRule: enableRule(ruleId:$ruleId){
    successMessage
    code
  }
}`;


export const DELETE_AR_RULE = gql`query deleteARRule($ruleId: String!){
  deleteARRule: deleteRule(ruleId:$ruleId){
    successMessage
    code
  }
}`;

export const CREATE_AR_RULE = gql`mutation($ruleReq: AutomationRule!) {
  createARRule: createRule(ruleReq:$ruleReq){
    successMessage
    code
  }
}`

export const UPDATE_AR_RULE = gql`query($ruleId: String!, $ruleReq: AutomationRule!) {
  updateARRule : updateRule(
    ruleId:$ruleId
    ruleReq:$ruleReq
  ){
    successMessage
    code
  }
}`;

export const GET_SLACKS = gql`query{
    getSlacks : getSlackListForTeam{
      IntegrationID
      channel
    }
}`;

export const GET_EMAILS = gql`query{
  getEmails : getEmailListForTeam{
    Email
  }
}`;
