var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1200" },
      model: {
        value: _vm.showCreateAR,
        callback: function($$v) {
          _vm.showCreateAR = $$v
        },
        expression: "showCreateAR"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline grey lighten-2 d-flex justify-space-between px-5 py-3 mb-0"
            },
            [
              _vm.mode == 1
                ? _c("h5", { staticClass: "text-h6" }, [
                    _vm._v(_vm._s(_vm.$t("createAR.createAutoRule")))
                  ])
                : _vm._e(),
              _vm.mode == 2
                ? _c("h5", { staticClass: "text-h6" }, [
                    _vm._v(_vm._s(_vm.$t("createAR.editAutoRule")))
                  ])
                : _vm._e(),
              _c("v-icon", { on: { click: _vm.close } }, [_vm._v("mdi-close")])
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-5" },
            [
              _c(
                "v-container",
                { staticClass: "stepper-template", attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "v-card",
                      { staticClass: "mb-12", attrs: { elevation: "0" } },
                      [
                        _c("v-card-title", {
                          staticClass:
                            "headline lighten-2 d-flex justify-space-between px-5 py-3 mb-0"
                        }),
                        _c(
                          "v-card-text",
                          { staticClass: "px-5" },
                          [
                            _c(
                              "v-row",
                              { attrs: { align: "center" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "field" },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.forLevels,
                                            "item-text": "levelName",
                                            "item-value": "levelId",
                                            label: "Create Rule For",
                                            outlined: "",
                                            dense: "",
                                            "return-object": ""
                                          },
                                          on: { change: _vm.levelChanged },
                                          model: {
                                            value: _vm.selectedForLevel,
                                            callback: function($$v) {
                                              _vm.selectedForLevel = $$v
                                            },
                                            expression: "selectedForLevel"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "field" },
                                      [
                                        _vm.selectedForLevel.levelId != 0
                                          ? _c("v-select", {
                                              attrs: {
                                                items: _vm.fromLevels,
                                                "item-text": "levelName",
                                                "item-value": "levelId",
                                                label: "From Selected",
                                                outlined: "",
                                                dense: "",
                                                "return-object": ""
                                              },
                                              on: { change: _vm.fromChanged },
                                              model: {
                                                value: _vm.selectedFromLevel,
                                                callback: function($$v) {
                                                  _vm.selectedFromLevel = $$v
                                                },
                                                expression: "selectedFromLevel"
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm.selectedFromLevel.levelId == 3 ||
                                _vm.selectedFromLevel.levelId == 4 ||
                                _vm.selectedFromLevel.levelId == 5 ||
                                _vm.selectedFromLevel.levelId == 2
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "3" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticStyle: {
                                                  "margin-top": "-28px"
                                                },
                                                attrs: {
                                                  color: "success",
                                                  depressed: "",
                                                  medium: ""
                                                },
                                                on: { click: _vm.choosePop }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("createAR.choose")
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.selectedFromLevel.levelId == 1
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "3" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _vm.selectedForLevel.levelId != 0
                                              ? _c("v-select", {
                                                  attrs: {
                                                    items: _vm.orgList,
                                                    "item-text": "orgName",
                                                    "item-value": "orgId",
                                                    label: "Select Orgs",
                                                    outlined: "",
                                                    dense: "",
                                                    multiple: "",
                                                    "return-object": ""
                                                  },
                                                  on: {
                                                    change: _vm.orgChanged
                                                  },
                                                  model: {
                                                    value: _vm.selectedOrgs,
                                                    callback: function($$v) {
                                                      _vm.selectedOrgs = $$v
                                                    },
                                                    expression: "selectedOrgs"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "1" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "field" },
                                      [
                                        (_vm.selectedOrgs.length > 0 ||
                                          _vm.apps.length > 0 ||
                                          _vm.campaigns.length > 0 ||
                                          _vm.adGroups.length > 0 ||
                                          _vm.keywords.length > 0) &&
                                        _vm.selectedFromLevel.levelId > 0
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticStyle: {
                                                  "margin-top": "-28px"
                                                },
                                                attrs: { color: "error" },
                                                on: { click: _vm.clearFor }
                                              },
                                              [_vm._v(" mdi-close ")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _vm.selectedOrgs.length == 0 &&
                                _vm.apps.length == 0 &&
                                _vm.campaigns.length == 0 &&
                                _vm.adGroups.length == 0 &&
                                _vm.keywords.length == 0
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "12", sm: "12" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticStyle: {
                                                  color: "#f66a60"
                                                }
                                              },
                                              [_vm._v("mdi-triangle")]
                                            ),
                                            _c(
                                              "v-card-text",
                                              { staticClass: "red--text" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "createAR.chooseAutoRule"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.selectedFromLevel.levelId == 2
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "12", sm: "12" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _vm.apps.length > 0
                                              ? _c("h5", [
                                                  _vm._v("Apps selected")
                                                ])
                                              : _vm._e(),
                                            _vm._l(_vm.apps, function(
                                              camp,
                                              idx
                                            ) {
                                              return _c(
                                                "v-chip",
                                                {
                                                  key: camp.adamId,
                                                  staticStyle: {
                                                    margin: "0px 10px"
                                                  },
                                                  attrs: {
                                                    color: "#eee",
                                                    "text-color": "#222"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(camp.appName) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticStyle: {
                                                        color: "primary",
                                                        "margin-left": "5px"
                                                      },
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.removeAppfromChoose(
                                                            idx
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("mdi-close ")]
                                                  )
                                                ],
                                                1
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedFromLevel.levelId == 3
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "12", sm: "12" }
                                      },
                                      [
                                        _vm.campaigns.length > 0
                                          ? _c("h5", [
                                              _vm._v("Campaigns selected")
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          _vm._l(_vm.campaigns, function(
                                            camp,
                                            idx
                                          ) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: camp.id,
                                                staticStyle: {
                                                  margin: "0px 10px"
                                                },
                                                attrs: {
                                                  color: "#eee",
                                                  "text-color": "#222"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(camp.name) + " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      color: "primary",
                                                      "margin-left": "5px"
                                                    },
                                                    attrs: { small: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeCampfromChoose(
                                                          idx
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("mdi-close ")]
                                                )
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedFromLevel.levelId == 4
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "12", sm: "12" }
                                      },
                                      [
                                        _vm.adGroups.length > 0
                                          ? _c("h5", [
                                              _vm._v("AdGroups selected")
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          _vm._l(_vm.adGroups, function(
                                            camp,
                                            idx
                                          ) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: camp.id,
                                                staticStyle: {
                                                  margin: "0px 10px"
                                                },
                                                attrs: {
                                                  color: "#eee",
                                                  "text-color": "#222"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(camp.name) + " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      color: "primary",
                                                      "margin-left": "5px"
                                                    },
                                                    attrs: { small: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeAdfromChoose(
                                                          idx
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("mdi-close ")]
                                                )
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedFromLevel.levelId == 5
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "12", sm: "12" }
                                      },
                                      [
                                        _vm.keywords.length > 0
                                          ? _c("h5", [
                                              _vm._v("Keywords selected")
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          _vm._l(_vm.keywords, function(
                                            camp,
                                            idx
                                          ) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: camp.id,
                                                staticStyle: {
                                                  margin: "0px 10px"
                                                },
                                                attrs: {
                                                  color: "#eee",
                                                  "text-color": "#222"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(camp.name) + " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      color: "primary",
                                                      "margin-left": "5px"
                                                    },
                                                    attrs: { small: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeKeyfromChoose(
                                                          idx
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("mdi-close ")]
                                                )
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            [
                              (_vm.selectedOrgs.length > 0 ||
                                _vm.apps.length > 0 ||
                                _vm.campaigns.length > 0 ||
                                _vm.adGroups.length > 0 ||
                                _vm.keywords.length > 0) &&
                              _vm.selectedFromLevel.levelId > 0
                                ? _c(
                                    "v-stepper",
                                    {
                                      staticStyle: { "box-shadow": "none" },
                                      on: {
                                        change: function($event) {
                                          return _vm.stepChanged($event)
                                        }
                                      },
                                      model: {
                                        value: _vm.step,
                                        callback: function($$v) {
                                          _vm.step = $$v
                                        },
                                        expression: "step"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-stepper-header",
                                        [
                                          _c(
                                            "v-stepper-step",
                                            {
                                              staticClass: "pa-2",
                                              attrs: {
                                                complete: _vm.step > 1,
                                                step: "1",
                                                editable: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "createAR.conditions"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _c(
                                            "v-stepper-step",
                                            {
                                              staticClass: "pa-2",
                                              attrs: {
                                                complete: _vm.step > 2,
                                                step: "2",
                                                editable:
                                                  _vm.aiConditionFormvalid
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("createAR.actions")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _c(
                                            "v-stepper-step",
                                            {
                                              staticClass: "pa-2",
                                              attrs: {
                                                step: "3",
                                                editable:
                                                  _vm.aiConditionFormvalid &&
                                                  _vm.aiItemFormvalid
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("createAR.settings")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-stepper-items",
                                        [
                                          _c(
                                            "v-stepper-content",
                                            {
                                              staticClass: "pa-2",
                                              attrs: { step: "1" }
                                            },
                                            [
                                              _c(
                                                "v-form",
                                                {
                                                  ref: "aiConditionForm",
                                                  model: {
                                                    value:
                                                      _vm.aiConditionFormvalid,
                                                    callback: function($$v) {
                                                      _vm.aiConditionFormvalid = $$v
                                                    },
                                                    expression:
                                                      "aiConditionFormvalid"
                                                  }
                                                },
                                                [
                                                  _vm._l(_vm.acItems, function(
                                                    acItem,
                                                    index
                                                  ) {
                                                    return _c(
                                                      "v-card",
                                                      {
                                                        key: index,
                                                        staticClass: "mb-12",
                                                        attrs: {
                                                          elevation: "2"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass:
                                                              "headline grey lighten-3 d-flex justify-space-between px-5 py-3 mb-0 customAI-1"
                                                          },
                                                          [
                                                            _c(
                                                              "h5",
                                                              {
                                                                staticClass:
                                                                  "text-h6"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "createAR.condition"
                                                                      )
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      index + 1
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm.acItems.length >
                                                            1
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "error"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteConditionItem(
                                                                          index
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-delete"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass: "px-5"
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                attrs: {
                                                                  align:
                                                                    "center"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols:
                                                                        "12",
                                                                      sm: "2"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-select",
                                                                      {
                                                                        staticClass:
                                                                          "v-sel-custom",
                                                                        attrs: {
                                                                          items:
                                                                            acItem.ciFieldList,
                                                                          "item-text":
                                                                            "field",
                                                                          "item-value":
                                                                            "id",
                                                                          label:
                                                                            "Select Field",
                                                                          outlined:
                                                                            "",
                                                                          dense:
                                                                            "",
                                                                          "return-object":
                                                                            "",
                                                                          rules:
                                                                            _vm.vSelectRules
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.conditionItemChanged(
                                                                              $event,
                                                                              index
                                                                            )
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            acItem.ciField,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              acItem,
                                                                              "ciField",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "acItem.ciField"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols:
                                                                        "12",
                                                                      sm: "3"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "field"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-select",
                                                                          {
                                                                            staticClass:
                                                                              "v-sel-custom",
                                                                            attrs: {
                                                                              items:
                                                                                _vm.allConOperationItems,
                                                                              label:
                                                                                "Select One",
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              rules:
                                                                                _vm.vSelectRules
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                acItem.ciOperation,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  acItem,
                                                                                  "ciOperation",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "acItem.ciOperation"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                ),
                                                                acItem.selType ==
                                                                  1 &&
                                                                _vm
                                                                  .selectedForLevel
                                                                  .levelId == 3
                                                                  ? _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "3"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-select",
                                                                              {
                                                                                staticClass:
                                                                                  "v-sel-custom",
                                                                                attrs: {
                                                                                  items:
                                                                                    _vm.allConOperatorItems,
                                                                                  label:
                                                                                    "Select One",
                                                                                  outlined:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  rules:
                                                                                    _vm.vSelectRules
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    acItem.ciOperator,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      acItem,
                                                                                      "ciOperator",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "acItem.ciOperator"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                acItem.selType ==
                                                                  1 &&
                                                                _vm
                                                                  .selectedForLevel
                                                                  .levelId != 3
                                                                  ? _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "3"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-select",
                                                                              {
                                                                                staticClass:
                                                                                  "v-sel-custom",
                                                                                attrs: {
                                                                                  items:
                                                                                    _vm.allConOperatorItems1,
                                                                                  label:
                                                                                    "Select One",
                                                                                  outlined:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  rules:
                                                                                    _vm.vSelectRules
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    acItem.ciOperator,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      acItem,
                                                                                      "ciOperator",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "acItem.ciOperator"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                acItem.ciOperation ==
                                                                  _vm
                                                                    .cIOperationsEnum
                                                                    .GREATER_THAN ||
                                                                acItem.ciOperation ==
                                                                  _vm
                                                                    .cIOperationsEnum
                                                                    .LESS_THAN
                                                                  ? _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "2"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "v-sel-custom",
                                                                            attrs: {
                                                                              label:
                                                                                "Enter Value",
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              type:
                                                                                "number",
                                                                              rules:
                                                                                _vm.emptyStringRule
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                acItem.ciValue,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  acItem,
                                                                                  "ciValue",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "acItem.ciValue"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                acItem.ciOperation ==
                                                                  _vm
                                                                    .cIOperationsEnum
                                                                    .IN_RANGE ||
                                                                acItem.ciOperation ==
                                                                  _vm
                                                                    .cIOperationsEnum
                                                                    .NOT_IN_RANGE
                                                                  ? _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "2"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "v-sel-custom",
                                                                            attrs: {
                                                                              label:
                                                                                "From Value",
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              type:
                                                                                "number",
                                                                              rules: [
                                                                                function(
                                                                                  v
                                                                                ) {
                                                                                  return (
                                                                                    v <
                                                                                      parseFloat(
                                                                                        acItem.ciToValue
                                                                                      ) ||
                                                                                    "Must be lesser than To value"
                                                                                  )
                                                                                },
                                                                                function(
                                                                                  v
                                                                                ) {
                                                                                  return (
                                                                                    v !=
                                                                                      0 ||
                                                                                    "Cannot be 0"
                                                                                  )
                                                                                },
                                                                                function(
                                                                                  v
                                                                                ) {
                                                                                  return (
                                                                                    v !=
                                                                                      "" ||
                                                                                    "Please enter a value"
                                                                                  )
                                                                                },
                                                                                function(
                                                                                  v
                                                                                ) {
                                                                                  return (
                                                                                    v !=
                                                                                      null ||
                                                                                    "Please enter a value"
                                                                                  )
                                                                                }
                                                                              ]
                                                                            },
                                                                            on: {
                                                                              change:
                                                                                _vm.validateRange
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                acItem.ciFromValue,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  acItem,
                                                                                  "ciFromValue",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "acItem.ciFromValue"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                acItem.ciOperation ==
                                                                  _vm
                                                                    .cIOperationsEnum
                                                                    .IN_RANGE ||
                                                                acItem.ciOperation ==
                                                                  _vm
                                                                    .cIOperationsEnum
                                                                    .NOT_IN_RANGE
                                                                  ? _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "2"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "v-sel-custom",
                                                                            attrs: {
                                                                              label:
                                                                                "To Value",
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              type:
                                                                                "number",
                                                                              rules: [
                                                                                function(
                                                                                  v
                                                                                ) {
                                                                                  return (
                                                                                    v >
                                                                                      parseFloat(
                                                                                        acItem.ciFromValue
                                                                                      ) ||
                                                                                    "Must be higher than from value"
                                                                                  )
                                                                                },
                                                                                function(
                                                                                  v
                                                                                ) {
                                                                                  return (
                                                                                    v !=
                                                                                      0 ||
                                                                                    "Cannot be 0"
                                                                                  )
                                                                                },
                                                                                function(
                                                                                  v
                                                                                ) {
                                                                                  return (
                                                                                    v !=
                                                                                      "" ||
                                                                                    "Please enter a value"
                                                                                  )
                                                                                },
                                                                                function(
                                                                                  v
                                                                                ) {
                                                                                  return (
                                                                                    v !=
                                                                                      null ||
                                                                                    "Please enter a value"
                                                                                  )
                                                                                }
                                                                              ]
                                                                            },
                                                                            on: {
                                                                              change:
                                                                                _vm.validateRange
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                acItem.ciToValue,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  acItem,
                                                                                  "ciToValue",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "acItem.ciToValue"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "success",
                                                                depressed: "",
                                                                large: ""
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.addConditionItem
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-plus"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "createAR.addCondition"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    attrs: { horizontal: "" }
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12"
                                                          }
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "createAR.andCondition"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12"
                                                          }
                                                        },
                                                        [
                                                          _c("v-select", {
                                                            staticClass:
                                                              "v-sel-custom",
                                                            attrs: {
                                                              items:
                                                                _vm.andOrTypes,
                                                              label:
                                                                "Select One",
                                                              outlined: "",
                                                              dense: "",
                                                              rules:
                                                                _vm.vSelectRules
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedAndOrtype,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selectedAndOrtype = $$v
                                                              },
                                                              expression:
                                                                "selectedAndOrtype"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12"
                                                          }
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "createAR.checkCond"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12"
                                                          }
                                                        },
                                                        [
                                                          _c("v-select", {
                                                            staticClass:
                                                              "v-sel-custom",
                                                            attrs: {
                                                              items:
                                                                _vm.ciTimeRanges,
                                                              label:
                                                                "Select One",
                                                              outlined: "",
                                                              dense: "",
                                                              rules:
                                                                _vm.vSelectRules
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedciTimeRange,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selectedciTimeRange = $$v
                                                              },
                                                              expression:
                                                                "selectedciTimeRange"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm.selectedciTimeRange ==
                                                  _vm.cITimeRangesEnum
                                                    .CUSTOM_HOUR
                                                    ? _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "v-sel-custom",
                                                                  attrs: {
                                                                    label:
                                                                      "Custom Hour",
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    type:
                                                                      "number",
                                                                    rules:
                                                                      _vm.customHrRules
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.ciTimeRangeCustomHr,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.ciTimeRangeCustomHr = $$v
                                                                    },
                                                                    expression:
                                                                      "ciTimeRangeCustomHr"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-stepper-content",
                                            {
                                              staticClass: "pa-2",
                                              attrs: { step: "2" }
                                            },
                                            [
                                              _c(
                                                "v-form",
                                                {
                                                  ref: "aiItemForm",
                                                  model: {
                                                    value: _vm.aiItemFormvalid,
                                                    callback: function($$v) {
                                                      _vm.aiItemFormvalid = $$v
                                                    },
                                                    expression:
                                                      "aiItemFormvalid"
                                                  }
                                                },
                                                [
                                                  _vm._l(_vm.aiItems, function(
                                                    aiItem,
                                                    index
                                                  ) {
                                                    return _c(
                                                      "v-card",
                                                      {
                                                        key: aiItem["id"],
                                                        staticClass: "mb-12",
                                                        attrs: {
                                                          elevation: "2"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass:
                                                              "headline grey lighten-3 d-flex justify-space-between px-5 py-3 mb-0 customAI-1"
                                                          },
                                                          [
                                                            _c(
                                                              "h5",
                                                              {
                                                                staticClass:
                                                                  "text-h6"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "createAR.action"
                                                                    )
                                                                  ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      index + 1
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _vm.aiItems.length >
                                                            1
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "error"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteActionItem(
                                                                          index
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-delete"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass: "px-5"
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                attrs: {
                                                                  align:
                                                                    "center"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols:
                                                                        "12",
                                                                      sm: "6"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "field"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-select",
                                                                          {
                                                                            staticClass:
                                                                              "v-sel-custom",
                                                                            attrs: {
                                                                              items:
                                                                                aiItem.aiActionList,
                                                                              "item-text":
                                                                                "action",
                                                                              "item-value":
                                                                                "id",
                                                                              label:
                                                                                "Select Action",
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "return-object":
                                                                                "",
                                                                              rules:
                                                                                _vm.vSelectRules
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.actionItemChanged(
                                                                                  $event,
                                                                                  index
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                aiItem.aiTypeObj,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  aiItem,
                                                                                  "aiTypeObj",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "aiItem.aiTypeObj"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                ),
                                                                aiItem.selAction ==
                                                                1
                                                                  ? _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "6"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-select",
                                                                              {
                                                                                staticClass:
                                                                                  "v-sel-custom",
                                                                                attrs: {
                                                                                  items:
                                                                                    _vm.emailList,
                                                                                  "item-text":
                                                                                    "Email",
                                                                                  "item-value":
                                                                                    "Email",
                                                                                  label:
                                                                                    "Select Email",
                                                                                  multiple:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  "return-object":
                                                                                    "",
                                                                                  disabled:
                                                                                    aiItem.aiExtChecked,
                                                                                  rules:
                                                                                    _vm.vSelectRules
                                                                                },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.emailChanged
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    _vm
                                                                                      .emailList
                                                                                      .length >
                                                                                    0
                                                                                      ? {
                                                                                          key:
                                                                                            "prepend-item",
                                                                                          fn: function() {
                                                                                            return [
                                                                                              _c(
                                                                                                "v-divider",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mt-2"
                                                                                                }
                                                                                              ),
                                                                                              _c(
                                                                                                "v-list-item",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    ripple:
                                                                                                      ""
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.toggle(
                                                                                                        index
                                                                                                      )
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-list-item-action",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            color: aiItem.aiEmail
                                                                                                              ? "primary"
                                                                                                              : "success"
                                                                                                          }
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm.selectAllIcon
                                                                                                              ) +
                                                                                                              " "
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-list-item-content",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-list-item-title",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm.$t(
                                                                                                                  "createAR.selectAll"
                                                                                                                )
                                                                                                              ) +
                                                                                                              " "
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                "v-divider",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mt-2"
                                                                                                }
                                                                                              )
                                                                                            ]
                                                                                          },
                                                                                          proxy: true
                                                                                        }
                                                                                      : null
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                                model: {
                                                                                  value:
                                                                                    aiItem.aiEmail,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      aiItem,
                                                                                      "aiEmail",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "aiItem.aiEmail"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                aiItem.selAction ==
                                                                2
                                                                  ? _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "6"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-select",
                                                                              {
                                                                                staticClass:
                                                                                  "v-sel-custom",
                                                                                attrs: {
                                                                                  items:
                                                                                    _vm.slackChannelList,
                                                                                  label:
                                                                                    "Select Channel",
                                                                                  "item-value":
                                                                                    "IntegrationID",
                                                                                  "item-text":
                                                                                    "channel",
                                                                                  outlined:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  multiple:
                                                                                    "",
                                                                                  rules:
                                                                                    _vm.vSelectRules
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    _vm
                                                                                      .slackChannelList
                                                                                      .length >
                                                                                    0
                                                                                      ? {
                                                                                          key:
                                                                                            "prepend-item",
                                                                                          fn: function() {
                                                                                            return [
                                                                                              _c(
                                                                                                "v-divider",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mt-2"
                                                                                                }
                                                                                              ),
                                                                                              _c(
                                                                                                "v-list-item",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    ripple:
                                                                                                      ""
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.toggleSlack(
                                                                                                        index
                                                                                                      )
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-list-item-action",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            color: aiItem.aiSlack
                                                                                                              ? "primary"
                                                                                                              : "success"
                                                                                                          }
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm.selectAllSlackIcon
                                                                                                              ) +
                                                                                                              " "
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-list-item-content",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-list-item-title",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm.$t(
                                                                                                                  "createAR.selectAll"
                                                                                                                )
                                                                                                              ) +
                                                                                                              " "
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                "v-divider",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mt-2"
                                                                                                }
                                                                                              )
                                                                                            ]
                                                                                          },
                                                                                          proxy: true
                                                                                        }
                                                                                      : null
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                                model: {
                                                                                  value:
                                                                                    aiItem.aiSlack,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      aiItem,
                                                                                      "aiSlack",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "aiItem.aiSlack"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            ),
                                                            aiItem.selAction ==
                                                            1
                                                              ? _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "3"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-checkbox",
                                                                          {
                                                                            attrs: {
                                                                              label:
                                                                                "Add other email",
                                                                              color:
                                                                                "success",
                                                                              "hide-details":
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.extEmailChecked(
                                                                                  index
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                aiItem.aiExtChecked,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  aiItem,
                                                                                  "aiExtChecked",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "aiItem.aiExtChecked"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            aiItem.selAction ==
                                                              1 &&
                                                            aiItem.aiExtChecked
                                                              ? _c(
                                                                  "v-row",
                                                                  {
                                                                    attrs: {
                                                                      align:
                                                                        "center"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "12"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-combobox",
                                                                          {
                                                                            staticClass:
                                                                              "v-sel-custom",
                                                                            attrs: {
                                                                              multiple:
                                                                                "",
                                                                              label:
                                                                                "Enter an Email Id and press enter to add more",
                                                                              outlined:
                                                                                "",
                                                                              chips:
                                                                                "",
                                                                              "deletable-chips":
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              rules:
                                                                                _vm.csvEmailRules
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                aiItem.externalEmailId,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  aiItem,
                                                                                  "externalEmailId",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "aiItem.externalEmailId"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            aiItem.selAction ==
                                                              1 &&
                                                            aiItem.aiExtChecked &&
                                                            _vm.improperEmail
                                                              ? _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "12"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            staticStyle: {
                                                                              color:
                                                                                "red"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "createAR.enterEmail"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.selectedForLevel
                                                              .levelId == 6 &&
                                                            (aiItem.selAction ==
                                                              7 ||
                                                              aiItem.selAction ==
                                                                8)
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          align:
                                                                            "center"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              sm:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "field"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "createAR.addTo"
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    aiItem.selAction ==
                                                                    7
                                                                      ? _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "12"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-sel-custom",
                                                                                        attrs: {
                                                                                          items:
                                                                                            _vm.addToModelList,
                                                                                          label:
                                                                                            "Select One",
                                                                                          outlined:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          rules:
                                                                                            _vm.vSelectRules
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            aiItem.aiAddTo,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              aiItem,
                                                                                              "aiAddTo",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "aiItem.aiAddTo"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    aiItem.selAction ==
                                                                    8
                                                                      ? _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "12"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-sel-custom",
                                                                                        attrs: {
                                                                                          items:
                                                                                            _vm.addToModelListNeg,
                                                                                          label:
                                                                                            "Select One",
                                                                                          outlined:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          rules:
                                                                                            _vm.vSelectRules
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            aiItem.aiAddTo,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              aiItem,
                                                                                              "aiAddTo",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "aiItem.aiAddTo"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          align:
                                                                            "center"
                                                                        }
                                                                      },
                                                                      [
                                                                        aiItem.aiAddTo ==
                                                                        _vm
                                                                          .aiItemAddTo
                                                                          .ALL_SELECTED_ADGROUPS
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "12"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs: {
                                                                                          color:
                                                                                            "primary"
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openAdGroup(
                                                                                              index
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs: {
                                                                                              small:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-pencil"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "createAR.selectAdgroups"
                                                                                                )
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          align:
                                                                            "center"
                                                                        }
                                                                      },
                                                                      [
                                                                        aiItem.aiAddTo ==
                                                                        _vm
                                                                          .aiItemAddTo
                                                                          .ALL_SELECTED_CAMPAIGNS
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "12"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs: {
                                                                                          color:
                                                                                            "primary"
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openCampaign(
                                                                                              index
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs: {
                                                                                              small:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-pencil"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "createAR.selectCampaigns"
                                                                                                )
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          align:
                                                                            "center"
                                                                        }
                                                                      },
                                                                      [
                                                                        aiItem.aiAddTo ==
                                                                        _vm
                                                                          .aiItemAddTo
                                                                          .ALL_ADGROUPS_SELECTED_CAMPAIGNS
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "12"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs: {
                                                                                          color:
                                                                                            "primary"
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openCampaign(
                                                                                              index
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs: {
                                                                                              small:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-pencil"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "createAR.selectCampaigns"
                                                                                                )
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    ),
                                                                    aiItem.aiAddTo ==
                                                                    _vm
                                                                      .aiItemAddTo
                                                                      .ALL_SELECTED_ADGROUPS
                                                                      ? _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "text-center",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "12"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  _vm._l(
                                                                                    _vm.selAdGroups,
                                                                                    function(
                                                                                      camp,
                                                                                      idx
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-chip",
                                                                                        {
                                                                                          key:
                                                                                            camp.addToId,
                                                                                          staticStyle: {
                                                                                            margin:
                                                                                              "0px 10px"
                                                                                          },
                                                                                          attrs: {
                                                                                            color:
                                                                                              "#eee",
                                                                                            "text-color":
                                                                                              "#222"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                camp.addToName
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticStyle: {
                                                                                                color:
                                                                                                  "primary",
                                                                                                "margin-left":
                                                                                                  "5px"
                                                                                              },
                                                                                              attrs: {
                                                                                                small:
                                                                                                  ""
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.removeAdfromKeyword(
                                                                                                    idx,
                                                                                                    index
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-close "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    aiItem.aiAddTo ==
                                                                      _vm
                                                                        .aiItemAddTo
                                                                        .ALL_SELECTED_CAMPAIGNS ||
                                                                    aiItem.aiAddTo ==
                                                                      _vm
                                                                        .aiItemAddTo
                                                                        .ALL_ADGROUPS_SELECTED_CAMPAIGNS
                                                                      ? _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "text-center",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "12"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  _vm._l(
                                                                                    _vm.selCampaigns,
                                                                                    function(
                                                                                      camp,
                                                                                      idx
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-chip",
                                                                                        {
                                                                                          key:
                                                                                            camp.addToId,
                                                                                          staticStyle: {
                                                                                            margin:
                                                                                              "0px 10px"
                                                                                          },
                                                                                          attrs: {
                                                                                            color:
                                                                                              "#eee",
                                                                                            "text-color":
                                                                                              "#222"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                camp.addToName
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticStyle: {
                                                                                                color:
                                                                                                  "primary",
                                                                                                "margin-left":
                                                                                                  "5px"
                                                                                              },
                                                                                              attrs: {
                                                                                                small:
                                                                                                  ""
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.removeCampfromKeyword(
                                                                                                    idx,
                                                                                                    index
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-close "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    aiItem.selAction ==
                                                                    7
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    attrs: {
                                                                                      cols:
                                                                                        "12",
                                                                                      sm:
                                                                                        "12"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "field"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "createAR.cptBid"
                                                                                                )
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-row",
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    attrs: {
                                                                                      cols:
                                                                                        "12",
                                                                                      sm:
                                                                                        "12"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "field"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-select",
                                                                                          {
                                                                                            staticClass:
                                                                                              "v-sel-custom",
                                                                                            attrs: {
                                                                                              items:
                                                                                                _vm.cptBidItems,
                                                                                              label:
                                                                                                "Select CPT Bid Type",
                                                                                              outlined:
                                                                                                "",
                                                                                              dense:
                                                                                                "",
                                                                                              rules:
                                                                                                _vm.vSelectRules
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                aiItem.aiCPTBidType,
                                                                                              callback: function(
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  aiItem,
                                                                                                  "aiCPTBidType",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "aiItem.aiCPTBidType"
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            aiItem.aiCPTBidType ==
                                                                            _vm
                                                                              .aiCPTBidTypesEnum
                                                                              .SET_TO_CUSTOM
                                                                              ? _c(
                                                                                  "v-row",
                                                                                  [
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        attrs: {
                                                                                          cols:
                                                                                            "12",
                                                                                          sm:
                                                                                            "12"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field"
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "label",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "createAR.customBid"
                                                                                                    )
                                                                                                  )
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                            aiItem.aiCPTBidType ==
                                                                            _vm
                                                                              .aiCPTBidTypesEnum
                                                                              .SET_TO_CUSTOM
                                                                              ? _c(
                                                                                  "v-row",
                                                                                  [
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        attrs: {
                                                                                          cols:
                                                                                            "12",
                                                                                          sm:
                                                                                            "12"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field"
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-text-field",
                                                                                              {
                                                                                                attrs: {
                                                                                                  "hide-details":
                                                                                                    "",
                                                                                                  "single-line":
                                                                                                    "",
                                                                                                  type:
                                                                                                    "number",
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  prefix:
                                                                                                    "$",
                                                                                                  rules:
                                                                                                    _vm.emptyStringRule
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    aiItem.aiCPTBidValue,
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      aiItem,
                                                                                                      "aiCPTBidValue",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "aiItem.aiCPTBidValue"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          align:
                                                                            "center"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              sm:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "field"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "createAR.matchType"
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          align:
                                                                            "center"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              sm:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "field"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-select",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-sel-custom",
                                                                                    attrs: {
                                                                                      items:
                                                                                        _vm.matchTypes,
                                                                                      label:
                                                                                        "Select Match Type",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      rules:
                                                                                        _vm.vSelectRules
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        aiItem.aiMatchType,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          aiItem,
                                                                                          "aiMatchType",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "aiItem.aiMatchType"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            aiItem.selAction ==
                                                              4 ||
                                                            aiItem.selAction ==
                                                              5 ||
                                                            aiItem.selAction ==
                                                              6
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      [
                                                                        (_vm
                                                                          .selectedForLevel
                                                                          .levelId ==
                                                                          4 ||
                                                                          _vm
                                                                            .selectedForLevel
                                                                            .levelId ==
                                                                            3) &&
                                                                        (aiItem.selAction ==
                                                                          4 ||
                                                                          aiItem.selAction ==
                                                                            5)
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "4"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-sel-custom",
                                                                                        attrs: {
                                                                                          items:
                                                                                            _vm.aiOperationList,
                                                                                          label:
                                                                                            "Select One",
                                                                                          outlined:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          rules:
                                                                                            _vm.vSelectRules
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            aiItem.aiOperation,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              aiItem,
                                                                                              "aiOperation",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "aiItem.aiOperation"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm
                                                                          .selectedForLevel
                                                                          .levelId ==
                                                                          5 &&
                                                                        (aiItem.selAction ==
                                                                          4 ||
                                                                          aiItem.selAction ==
                                                                            5)
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "4"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-sel-custom",
                                                                                        attrs: {
                                                                                          items:
                                                                                            _vm.aiOperationList3,
                                                                                          label:
                                                                                            "Select One",
                                                                                          outlined:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          rules:
                                                                                            _vm.vSelectRules
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            aiItem.aiOperation,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              aiItem,
                                                                                              "aiOperation",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "aiItem.aiOperation"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        aiItem.selAction ==
                                                                        6
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "4"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-sel-custom",
                                                                                        attrs: {
                                                                                          items:
                                                                                            _vm.aiOperationList2,
                                                                                          label:
                                                                                            "Select One",
                                                                                          outlined:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          rules:
                                                                                            _vm.vSelectRules
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            aiItem.aiOperation,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              aiItem,
                                                                                              "aiOperation",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "aiItem.aiOperation"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        aiItem.aiOperation !=
                                                                          _vm
                                                                            .aIOperationsEnum
                                                                            .SET_TO_AVG_CPA &&
                                                                        aiItem.aiOperation !=
                                                                          _vm
                                                                            .aIOperationsEnum
                                                                            .SET_TO_AVG_CPT
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "4"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "field"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-sel-custom",
                                                                                        attrs: {
                                                                                          items:
                                                                                            _vm.aiOperatorsList,
                                                                                          label:
                                                                                            "Select One",
                                                                                          outlined:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          rules:
                                                                                            _vm.vSelectRules
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            aiItem.aiOperators,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              aiItem,
                                                                                              "aiOperators",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "aiItem.aiOperators"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        aiItem.aiOperation !=
                                                                          _vm
                                                                            .aIOperationsEnum
                                                                            .SET_TO_AVG_CPA &&
                                                                        aiItem.aiOperation !=
                                                                          _vm
                                                                            .aIOperationsEnum
                                                                            .SET_TO_AVG_CPT
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "4"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-sel-custom",
                                                                                    attrs: {
                                                                                      label:
                                                                                        "Enter amount",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      type:
                                                                                        "number",
                                                                                      rules:
                                                                                        _vm.emptyStringRule
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        aiItem.aiValue,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          aiItem,
                                                                                          "aiValue",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "aiItem.aiValue"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        aiItem.aiOperation ==
                                                                          _vm
                                                                            .aIOperationsEnum
                                                                            .SET_TO_AVG_CPA ||
                                                                        aiItem.aiOperation ==
                                                                          _vm
                                                                            .aIOperationsEnum
                                                                            .SET_TO_AVG_CPT
                                                                          ? _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12",
                                                                                  sm:
                                                                                    "4"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-sel-custom",
                                                                                    attrs: {
                                                                                      label:
                                                                                        "Default amount",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      type:
                                                                                        "number",
                                                                                      prefix:
                                                                                        "$",
                                                                                      rules:
                                                                                        _vm.emptyStringRule
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        aiItem.aiValue,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          aiItem,
                                                                                          "aiValue",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "aiItem.aiValue"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "success",
                                                                depressed: "",
                                                                large: ""
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.addActionItem
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-plus"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "createAR.addAction"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider", {
                                                    attrs: { horizontal: "" }
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "4"
                                                          }
                                                        },
                                                        [
                                                          _c("v-checkbox", {
                                                            attrs: {
                                                              label:
                                                                "Limit Action frequency",
                                                              color: "success",
                                                              "hide-details": ""
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.limitActionFreqChanged
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.limitActionFreq,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.limitActionFreq = $$v
                                                              },
                                                              expression:
                                                                "limitActionFreq"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm.limitActionFreq
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "8"
                                                              }
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                staticClass:
                                                                  "v-sel-custom",
                                                                attrs: {
                                                                  items:
                                                                    _vm.limitActionFreqs,
                                                                  label:
                                                                    "Select Action Freq",
                                                                  outlined: "",
                                                                  dense: "",
                                                                  rules:
                                                                    _vm.vSelectRules
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.selectedActionFreq,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedActionFreq = $$v
                                                                  },
                                                                  expression:
                                                                    "selectedActionFreq"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-stepper-content",
                                            {
                                              staticClass: "pa-2",
                                              attrs: { step: "3" }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "pa-5",
                                                  attrs: { elevation: "0" }
                                                },
                                                [
                                                  _c(
                                                    "v-form",
                                                    {
                                                      ref: "ruleSettForm",
                                                      model: {
                                                        value:
                                                          _vm.ruleSettFormvalid,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.ruleSettFormvalid = $$v
                                                        },
                                                        expression:
                                                          "ruleSettFormvalid"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("v-divider", {
                                                                attrs: {
                                                                  horizontal: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "field"
                                                                },
                                                                [
                                                                  _c("label", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "createAR.chckFreq"
                                                                        )
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                staticClass:
                                                                  "v-sel-custom",
                                                                attrs: {
                                                                  items:
                                                                    _vm.actionFreqs,
                                                                  label:
                                                                    "Select Check Freq",
                                                                  outlined: "",
                                                                  dense: "",
                                                                  rules:
                                                                    _vm.vSelectRules
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.selectedCheckFreq,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedCheckFreq = $$v
                                                                  },
                                                                  expression:
                                                                    "selectedCheckFreq"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm.selectedCheckFreq ==
                                                      _vm
                                                        .ruleCheckFrequenciesEnum
                                                        .CUSTOM
                                                        ? _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      staticClass:
                                                                        "v-sel-custom",
                                                                      attrs: {
                                                                        label:
                                                                          "Custom Hour",
                                                                        outlined:
                                                                          "",
                                                                        dense:
                                                                          "",
                                                                        type:
                                                                          "number",
                                                                        rules:
                                                                          _vm.customHrRules
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.checkFreqCustomHr,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.checkFreqCustomHr = $$v
                                                                        },
                                                                        expression:
                                                                          "checkFreqCustomHr"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "4"
                                                              }
                                                            },
                                                            [
                                                              _c("v-checkbox", {
                                                                attrs: {
                                                                  label:
                                                                    "Exclude Days",
                                                                  "hide-details":
                                                                    ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.excludeDaysCheck,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.excludeDaysCheck = $$v
                                                                  },
                                                                  expression:
                                                                    "excludeDaysCheck"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm.excludeDaysCheck
                                                            ? _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "8"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      staticClass:
                                                                        "v-sel-custom",
                                                                      attrs: {
                                                                        items:
                                                                          _vm.excludeDays,
                                                                        label:
                                                                          "Select Days to exclude",
                                                                        multiple:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        dense:
                                                                          "",
                                                                        rules:
                                                                          _vm.excludeDaysRules
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.selectedExcludeDays,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.selectedExcludeDays = $$v
                                                                        },
                                                                        expression:
                                                                          "selectedExcludeDays"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "createAR.ruleName"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "v-sel-custom",
                                                                  attrs: {
                                                                    label:
                                                                      "Give a name for this rule",
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    rules:
                                                                      _vm.emptyStringRule
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.ruleName,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.ruleName = $$v
                                                                    },
                                                                    expression:
                                                                      "ruleName"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _c("v-divider", { attrs: { horizontal: "" } }),
          (_vm.selectedOrgs.length > 0 ||
            _vm.apps.length > 0 ||
            _vm.campaigns.length > 0 ||
            _vm.adGroups.length > 0 ||
            _vm.keywords.length > 0) &&
          _vm.selectedFromLevel.levelId > 0
            ? _c(
                "v-card-actions",
                { staticClass: "justify-end mt-7 pa-5" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "127",
                        color: "grey",
                        depressed: "",
                        outlined: ""
                      },
                      on: { click: _vm.close }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("createAR.cancel")) + " ")]
                  ),
                  _vm.step == 3
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            width: "127",
                            color: "success",
                            depressed: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.askConfirm()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.modeText) + " ")]
                      )
                    : _vm._e(),
                  _vm.step == 1
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            width: "127",
                            color: "success",
                            depressed: ""
                          },
                          on: { click: _vm.toStep2 }
                        },
                        [_vm._v(" > " + _vm._s(_vm.$t("createAR.step2")) + " ")]
                      )
                    : _vm._e(),
                  _vm.step == 2
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            width: "127",
                            color: "success",
                            depressed: ""
                          },
                          on: { click: _vm.toStep3 }
                        },
                        [_vm._v(" > " + _vm._s(_vm.$t("createAR.step3")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("SelectCampaign", {
        attrs: { openSelectCampaign: _vm.selectCampaign },
        on: {
          onCampaignSelection: function($event) {
            return _vm.campaignSelectedForChoose($event)
          }
        }
      }),
      _c("SelectAdGroup", {
        attrs: { settings: _vm.selectAdgroupForChoose },
        on: {
          onAdgroupSelection: function($event) {
            return _vm.adgroupsSelectedForChoose($event)
          }
        }
      }),
      _c("SelectCampaign", {
        attrs: { openSelectCampaign: _vm.selectCampaignForKeywords },
        on: {
          onCampaignSelection: function($event) {
            return _vm.campaignSelectedForKeyword($event)
          }
        }
      }),
      _c("SelectAdGroup", {
        attrs: { settings: _vm.selectAdgroupForKeywords },
        on: {
          onAdgroupSelection: function($event) {
            return _vm.adgroupsSelectedForKeyword($event)
          }
        }
      }),
      _c("SelectApps", {
        attrs: { selectApps: _vm.toSelectApps },
        on: {
          onAppSelection: function($event) {
            return _vm.appsSelectedForChoose($event)
          }
        }
      }),
      _c("SelectKeywords", {
        attrs: { selectKeywordData: _vm.toSelectKeys },
        on: {
          onkeywordSelection: function($event) {
            return _vm.keysSelectedForChoose($event)
          }
        }
      }),
      _c("DecisionAlert", {
        attrs: {
          DecisionAlert: _vm.decisionAlertFlag,
          DecisionAlertTitle: _vm.decisionAlertTitle,
          DecisionAlertMessage: _vm.decisionAlertMessage,
          DecisionAlertData: _vm.decisionAlertData
        },
        on: {
          emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
          emitDecisionAlertOkClicked: function($event) {
            return _vm.decisionOkAction($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }