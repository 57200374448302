<template>
  <div class="text-center">
    <v-dialog
      v-model="DecisionAlert"
      persistent
      width="300"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ DecisionAlertTitle }}
        </v-card-title>

        <v-card-text>
          {{ DecisionAlertMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="emitDecisionAlertCancelClicked"
          >
            {{$t('decisionAlert.cancel')}}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="emitDecisionAlertOkClicked"
          >
            {{$t('decisionAlert.ok')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name : 'DecisionAlert',
  data() {
    return {
      emitMsgOk : 'clicked Ok',
      emitMsgCancel : 'clicked Cancel',
    }
  },
  props:{
    DecisionAlert : {
      type: Boolean,
      default: false
    },
    DecisionAlertTitle : {
      type: String,
      default: 'Decision...'
    },
    DecisionAlertMessage : {
      type: String,
      default: 'Are you sure ?'
    },
    DecisionAlertData : {
      type: Object,
      default() {
        return;
      },
    },
  },
  watch: {
    DecisionAlertData: function (data) {
      //console.log(data);
    },
  },
  methods: {
    emitDecisionAlertOkClicked() {
      //console.log('emitDecisionAlertOkClicked');
      this.$emit('emitDecisionAlertOkClicked', this.DecisionAlertData);
    },
    emitDecisionAlertCancelClicked() {
      //console.log('emitDecisionAlertCancelClicked');
      this.$emit('emitDecisionAlertCancelClicked', this.emitMsgCancel);
    }
  }
})
</script>

