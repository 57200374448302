














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import {
  AIAddTo,
  AICPTBidTypes,
  RuleCheckFrequencies,
  ActionFrequencies,
  ARStatus,
  ForTypes,
  FromTypes,
  AIOperations,
  AIOperators,
  AIMatchTypes,
  ACFields,
  ARExcludeDays,
  AITypes,
  CITimeRanges,
  CIOperations,
  CIOperators,
  CIAndORTypes,
} from '@shared/enums/arenums';
import SelectCampaign from '../campaign/SelectCampaign.vue';
import SelectAdGroup from '../keywords/SelectAdGroup.vue';
import SelectApps from './SelectApps.vue';
import SelectKeywords from './SelectKeywords.vue';
import {
  CREATE_AR_RULE,
  GET_SLACKS,
  GET_EMAILS,
  UPDATE_AR_RULE,
} from '../../utils/arrules-queries';
import { v4 as uuidv4 } from 'uuid';
import { GET_CAMPAIGN_GROUPS } from 'src/utils/apollo-queries';
import DecisionAlert from '../DecisionAlert.vue';

export default Vue.extend({
  name: 'CreateRule',
  components: {
    SelectCampaign,
    SelectAdGroup,
    SelectApps,
    DecisionAlert,
    SelectKeywords,
  },
  data() {
    return {
      decisionAlertFlag: false,
      decisionAlertTitle: 'Are you sure ?',
      decisionAlertMessage: '',
      decisionAlertData: {},
      modeText: 'Create',
      mode: 1,
      rId: null,
      mongoId: null,
      toSelectApps: null,
      toSelectKeys: null,
      chooseCampNames: [],
      chooseAdGroups: [],
      selectedOrgs: [],
      orgList: [],
      csvEmailRules: [
        (v) => !!v || 'Please enter proper email ids',
        (v) =>
          /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/.test(
            v
          ) || 'Please enter proper email ids',
      ],
      improperEmail: false,
      selectAllEmails: false,
      selectAllSlacks: false,
      selectAllIcon: 'mdi-crop-square',
      selectAllSlackIcon: 'mdi-crop-square',
      ruleName: '',
      fullscreenLoader: null,
      aiItemAddTo: AIAddTo,
      aiCPTBidTypesEnum: AICPTBidTypes,
      forTypesEnum: ForTypes,
      fromTypesEnum: FromTypes,
      ruleCheckFrequenciesEnum: RuleCheckFrequencies,
      cITimeRangesEnum: CITimeRanges,
      aIOperationsEnum: AIOperations,
      cIOperationsEnum: CIOperations,
      cIOperatorsEnum: CIOperators,
      aCFieldsEnum: ACFields,
      showCreateAR: false,
      ruleObj: {},
      step: 1,
      createARFormValid: false,
      selectedAndOrtype: CIAndORTypes.AND,
      forLevels: [
        { levelName: 'Select Level', levelId: 0, value: '' },
        { levelName: 'Organization(s)', levelId: 1, value: ForTypes.ORG },
        { levelName: 'Application(s)', levelId: 2, value: ForTypes.APP },
        { levelName: 'Campaign(s)', levelId: 3, value: ForTypes.CAMPAIGN },
        { levelName: 'Ad Group(s)', levelId: 4, value: ForTypes.ADGROUP },
        { levelName: 'Keyword(s)', levelId: 5, value: ForTypes.KEYWORD },
        { levelName: 'Search Term(s)', levelId: 6, value: ForTypes.SEARCHTERM },
      ],
      selectedForLevel: { levelName: 'Select Level', levelId: 0, value: '' },
      fromLevels: [],
      AllFromLevels: [
        { levelName: 'Select', levelId: 0, value: '' },
        { levelName: 'Organization(s)', levelId: 1, value: FromTypes.ORG },
        { levelName: 'Application(s)', levelId: 2, value: FromTypes.APP },
        { levelName: 'Campaign(s)', levelId: 3, value: FromTypes.CAMPAIGN },
        { levelName: 'Ad Group(s)', levelId: 4, value: FromTypes.ADGROUP },
        { levelName: 'Keyword(s)', levelId: 5, value: FromTypes.KEYWORD },
      ],
      selectedFromLevel: { levelName: 'Select', levelId: 0, value: '' },
      forIn: 'In',
      forIns: ['In'],
      AllActionItems: [],
      emailList: [],
      aiItems: [],
      aiItemFormvalid: true,
      acItems: [],
      aiConditionFormvalid: true,
      slackChannelList: [],
      // addToModelListNeg: [
      //   { text: 'All Selected AdGroup(s)', value: AIAddTo.ALL_SELECTED_ADGROUPS },
      //   { text: 'All AdGroups of Selected Campaign(s)', value: AIAddTo.ALL_ADGROUPS_SELECTED_CAMPAIGNS },
      //   { text: 'All Selected Campaign(s)', value:AIAddTo.ALL_SELECTED_CAMPAIGNS}
      // ],
      // addToModelList: [
      //   { text: 'All Selected AdGroup(s)', value: AIAddTo.ALL_SELECTED_ADGROUPS },
      //   { text: 'All AdGroups of Selected Campaign(s)', value: AIAddTo.ALL_ADGROUPS_SELECTED_CAMPAIGNS  }
      // ],
      // matchTypes: [
      //   { text: 'Broad Match', value: AIMatchTypes.BROAD},
      //   { text: 'Exact Match', value: AIMatchTypes.EXACT}
      // ],
      // cptBidItems: [
      //   { text: 'Set To Custom', value: AICPTBidTypes.SET_TO_CUSTOM },
      //   { text: 'AdGroup Default Bid', value: AICPTBidTypes.ADGROUP_DEFAULT_BID }
      // ],
      limitActionFreq: false,
      // actionFreqs: [
      //   {text:'Hourly', value: RuleCheckFrequencies.HOURLY},
      //   {text:'Every 6 hours', value: RuleCheckFrequencies.EVERY_6_HRS},
      //   {text:'Every 12 hours', value: RuleCheckFrequencies.EVERY_12_HRS},
      //   {text:'Every day', value: RuleCheckFrequencies.EVERY_DAY},
      //   {text:'Custom hours', value: RuleCheckFrequencies.CUSTOM},
      // ],
      // limitActionFreqs:[
      //   {text:'Hourly', value: ActionFrequencies.HOURLY},
      //   {text:'Every 2 hours', value: ActionFrequencies.EVERY_2_HRS},
      //   {text:'Every 3 hours', value: ActionFrequencies.EVERY_3_HRS},
      //   {text:'Every 6 hours', value: ActionFrequencies.EVERY_6_HRS},
      //   {text:'Every 12 hours', value: ActionFrequencies.EVERY_12_HRS},
      //   {text:'Every day', value: ActionFrequencies.EVERY_DAY},
      //   {text:'Every 2 days', value: ActionFrequencies.EVERY_2ND_DAY},
      //   {text:'Every 3 days', value: ActionFrequencies.EVERY_3RD_DAY},
      //   {text:'Every week', value: ActionFrequencies.EVERY_WEEK},
      // ],
      selectedActionFreq: ActionFrequencies.EVERY_DAY,
      selectedCheckFreq: RuleCheckFrequencies.EVERY_DAY,
      checkFreqCustomHr: null,
      customHrRules: [
        (v) => !!v || 'Custom hour is required',
        (v) => (v > 0 && v < 23) || 'Custom hour must be between 1 and 23',
        (v) =>
          Number.isInteger(Number(v)) || 'The value must be an integer number',
      ],
      ruleSettFormvalid: true,
      excludeDaysCheck: false,
      // excludeDays: [
      //   { text: 'Monday', value: ARExcludeDays.MON },
      //   { text: 'Tuesday', value: ARExcludeDays.TUE },
      //   { text: 'Wednesday', value: ARExcludeDays.WED },
      //   { text: 'Thursday', value: ARExcludeDays.THU },
      //   { text: 'Friday', value: ARExcludeDays.FRI },
      //   { text: 'Saturday', value: ARExcludeDays.SAT },
      //   { text: 'Sunday', value: ARExcludeDays.SUN },
      // ],
      selectedExcludeDays: [],
      excludeDaysRules: [
        (v) => !!v || 'Select a day to exclude',
        (v) => v.length != 0 || 'Select a day to exclude',
        (v) => v.length != 7 || 'You cannot exclude all days',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
      ],
      vSelectRules: [(v) => v != null || 'Please select'],
      emptyStringRule: [
        (v) => v != '' || 'Please enter a value',
        (v) => v != 0 || 'Please enter a value',
        (v) => v != null || 'Please enter a value',
      ],
      selectedciTimeRange: CITimeRanges.LAST_30_DAYS,
      // ciTimeRanges: [
      //   { text: 'Last 1 hour', value: CITimeRanges.LAST_1_HOUR },
      //   { text: 'Last 1 day', value: CITimeRanges.LAST_1_DAY },
      //   { text: 'Last 7 days', value: CITimeRanges.LAST_7_DAYS },
      //   { text: 'Last 30 days', value: CITimeRanges.LAST_30_DAYS },
      //   { text: 'Last month', value: CITimeRanges.THIS_MONTH },
      //   { text: 'Custom hour', value: CITimeRanges.CUSTOM_HOUR },
      // ],
      ciTimeRangeCustomHr: null,
      allConItems: [
        { field: 'Spend', id: 1, type: 1, value: ACFields.SPEND },
        { field: 'Installs', id: 2, type: 2, value: ACFields.INSTALLS },
        { field: 'Impressions', id: 3, type: 2, value: ACFields.IMPRESSIONS },
        { field: 'CPA', id: 4, type: 1, value: ACFields.CPA },
        { field: 'CPT', id: 5, type: 1, value: ACFields.CPT },
        { field: 'TTR', id: 6, type: 2, value: ACFields.TTR },
        {
          field: 'Lat On Installs',
          id: 7,
          type: 2,
          value: ACFields.LAT_ON_INSTALLS,
        },
        {
          field: 'Lat Off Installs',
          id: 8,
          type: 2,
          value: ACFields.LAT_OFF_INSTALLS,
        },
        {
          field: 'Conversion Rate',
          id: 9,
          type: 2,
          value: ACFields.CONVERSION_RATE,
        },
        {
          field: 'New Downloads',
          id: 10,
          type: 2,
          value: ACFields.NEW_DOWNLOADS,
        },
        {
          field: 'Re Downloads',
          id: 11,
          type: 2,
          value: ACFields.RE_DOWNLOADS,
        },
        { field: 'Taps', id: 12, type: 2, value: ACFields.TAPS },
      ],
      // allConOperationItems: [
      //   { text: 'Greater Than', value: CIOperations.GREATER_THAN },
      //   { text: 'Less Than', value: CIOperations.LESS_THAN },
      //   { text: 'Range', value: CIOperations.IN_RANGE },
      //   { text: 'Not In Range', value: CIOperations.NOT_IN_RANGE },
      // ],
      // allConOperatorItems: [
      //   { text: '$ Amount', value: CIOperators.DOLLAR_AMOUNT },
      //   {
      //     text: '% Percent of Budget',
      //     value: CIOperators.PERCENTAGE_OF_BUDGET,
      //   },
      // ],
      // allConOperatorItems1: [
      //   { text: '$ Amount', value: CIOperators.DOLLAR_AMOUNT },
      // ],
      orgs: [],
      apps: [],
      campaigns: [],
      adGroups: [],
      keywords: [],
      // aiOperationList: [
      //   //{ text: 'Set to', value: AIOperations.SET_TO},
      //   { text: 'Decrease by', value: AIOperations.DECREASE_BY },
      //   { text: 'Increase by', value: AIOperations.INCREASE_BY },
      // ],
      // aiOperationList2: [
      //   //{ text: 'Set to', value: AIOperations.SET_TO},
      //   { text: 'Increase by', value: AIOperations.INCREASE_BY },
      // ],
      // aiOperationList3: [
      //   //{ text: 'Set to', value: AIOperations.SET_TO},
      //   { text: 'Decrease by', value: AIOperations.DECREASE_BY },
      //   { text: 'Increase by', value: AIOperations.INCREASE_BY },
      //   { text: 'Set to Average CPA', value: AIOperations.SET_TO_AVG_CPA },
      //   { text: 'Set to Average CPT', value: AIOperations.SET_TO_AVG_CPT },
      // ],
      // aiOperatorsList: [
      //   { text: '$ Value', value: AIOperators.VALUE },
      //   { text: '% Change', value: AIOperators.PERCENTAGE },
      // ],
      selectedAdgroups: [],
      selectedCampaign: [],
      totalCampaigns: 0,
      totalAdgroups: 0,
      selectCampaign: null,
      selectCampaignForKeywords: null,
      selectAdgroupForKeywords: null,
      selectAdgroupForChoose: null,
      adgroupDetails: null,
      campaignDetails: null,
      chosedData: [],
      campsFromChose: [],
      adGroupsFromChose: [],
      keysFromChose: [],
      selAdGroups: [],
      selCampaigns: [],
      editData: null,
    };
  },
  computed: {
    andOrTypes: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.andOrTypesText1'), value: CIAndORTypes.AND },
          { text: this.$t('createAR.andOrTypesText2'), value: CIAndORTypes.OR },
        ];
      },
    },

    addToModelListNeg: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.addToModelListNegText1'),
            value: AIAddTo.ALL_SELECTED_ADGROUPS,
          },
          {
            text: this.$t('createAR.addToModelListNegText2'),
            value: AIAddTo.ALL_ADGROUPS_SELECTED_CAMPAIGNS,
          },
          {
            text: this.$t('createAR.addToModelListNegText3'),
            value: AIAddTo.ALL_SELECTED_CAMPAIGNS,
          },
        ];
      },
    },

    addToModelList: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.addToModelListNegText1'),
            value: AIAddTo.ALL_SELECTED_ADGROUPS,
          },
          {
            text: this.$t('createAR.addToModelListNegText2'),
            value: AIAddTo.ALL_ADGROUPS_SELECTED_CAMPAIGNS,
          },
        ];
      },
    },

    matchTypes: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.matchTypesText1'), value: AIMatchTypes.BROAD },
          { text: this.$t('createAR.matchTypesText2'), value: AIMatchTypes.EXACT },
        ];
      },
    },

    cptBidItems: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.cptBidItemsText1'), value: AICPTBidTypes.SET_TO_CUSTOM },
          {
            text: this.$t('createAR.cptBidItemsText2'),
            value: AICPTBidTypes.ADGROUP_DEFAULT_BID,
          },
        ];
      },
    },

    actionFreqs: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.actionFreqsText1'), value: RuleCheckFrequencies.HOURLY },
          { text: this.$t('createAR.actionFreqsText2'), value: RuleCheckFrequencies.EVERY_6_HRS },
          { text: this.$t('createAR.actionFreqsText3'), value: RuleCheckFrequencies.EVERY_12_HRS },
          { text: this.$t('createAR.actionFreqsText4'), value: RuleCheckFrequencies.EVERY_DAY },
          { text: this.$t('createAR.actionFreqsText5'), value: RuleCheckFrequencies.CUSTOM },
        ];
      },
    },

    limitActionFreqs: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.actionFreqsText1'), value: ActionFrequencies.HOURLY },
          { text: this.$t('createAR.limitActionFreqsText1'), value: ActionFrequencies.EVERY_2_HRS },
          { text: this.$t('createAR.limitActionFreqsText2'), value: ActionFrequencies.EVERY_3_HRS },
          { text: this.$t('createAR.actionFreqsText2'), value: ActionFrequencies.EVERY_6_HRS },
          { text: this.$t('createAR.actionFreqsText3'), value: ActionFrequencies.EVERY_12_HRS },
          { text: this.$t('createAR.actionFreqsText4'), value: ActionFrequencies.EVERY_DAY },
          { text: this.$t('createAR.limitActionFreqsText3'), value: ActionFrequencies.EVERY_2ND_DAY },
          { text: this.$t('createAR.limitActionFreqsText4'), value: ActionFrequencies.EVERY_3RD_DAY },
          { text: this.$t('createAR.limitActionFreqsText5'), value: ActionFrequencies.EVERY_WEEK },
        ];
      },
    },

    excludeDays: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.mon'), value: ARExcludeDays.MON },
          { text: this.$t('createAR.tue'), value: ARExcludeDays.TUE },
          { text: this.$t('createAR.wed'), value: ARExcludeDays.WED },
          { text: this.$t('createAR.thu'), value: ARExcludeDays.THU },
          { text: this.$t('createAR.fri'), value: ARExcludeDays.FRI },
          { text: this.$t('createAR.sat'), value: ARExcludeDays.SAT },
          { text: this.$t('createAR.sun'), value: ARExcludeDays.SUN },
        ];
      },
    },

    ciTimeRanges: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.ciTimeRangesText1'), value: CITimeRanges.LAST_1_HOUR },
          { text: this.$t('createAR.ciTimeRangesText2'), value: CITimeRanges.LAST_1_DAY },
          { text: this.$t('createAR.ciTimeRangesText3'), value: CITimeRanges.LAST_7_DAYS },
          { text: this.$t('createAR.ciTimeRangesText4'), value: CITimeRanges.LAST_30_DAYS },
          { text: this.$t('createAR.ciTimeRangesText5'), value: CITimeRanges.THIS_MONTH },
          { text: this.$t('createAR.ciTimeRangesText6'), value: CITimeRanges.CUSTOM_HOUR },
        ];
      },
    },

    allConOperationItems: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.allConOperationText1'), value: CIOperations.GREATER_THAN },
          { text: this.$t('createAR.allConOperationText2'), value: CIOperations.LESS_THAN },
          { text: this.$t('createAR.allConOperationText3'), value: CIOperations.IN_RANGE },
          { text: this.$t('createAR.allConOperationText4'), value: CIOperations.NOT_IN_RANGE },
        ];
      },
    },

    allConOperatorItems: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.amount'), value: CIOperators.DOLLAR_AMOUNT },
          {
            text: this.$t('createAR.budget'),
            value: CIOperators.PERCENTAGE_OF_BUDGET,
          },
        ];
      },
    },

    allConOperatorItems1: {
      cache: false,
      get() {
        return [{ text: this.$t('createAR.amount'), value: CIOperators.DOLLAR_AMOUNT }];
      },
    },

    aiOperationList: {
      cache: false,
      get() {
        return [
          //{ text: 'Set to', value: AIOperations.SET_TO},
          { text: this.$t('createAR.incBy'), value: AIOperations.DECREASE_BY },
          { text: this.$t('createAR.decBy'), value: AIOperations.INCREASE_BY },
        ];
      },
    },

    aiOperationList2: {
      cache: false,
      get() {
        return [
          //{ text: 'Set to', value: AIOperations.SET_TO},
          { text: this.$t('createAR.incBy'), value: AIOperations.INCREASE_BY },
        ];
      },
    },

    aiOperationList3: {
      cache: false,
      get() {
        return [
          //{ text: 'Set to', value: AIOperations.SET_TO},
          { text: this.$t('createAR.decBy'), value: AIOperations.DECREASE_BY },
          { text: this.$t('createAR.incBy'), value: AIOperations.INCREASE_BY },
          { text: this.$t('createAR.setToCPA'), value: AIOperations.SET_TO_AVG_CPA },
          { text: this.$t('createAR.setToCPT'), value: AIOperations.SET_TO_AVG_CPT },
        ];
      },
    },

    aiOperatorsList: {
      cache: false,
      get() {
        return [
          { text: this.$t('createAR.value'), value: AIOperators.VALUE },
          { text: this.$t('createAR.change'), value: AIOperators.PERCENTAGE },
        ];
      },
    },
  },
  props: {
    fromRulesPage: {
      type: Object,
      required: false,
    },
  },
  watch: {
    fromRulesPage: function (data) {
      this.showCreateAR = true;
      this.mode = data.mode;
      if (data.mode == 2) {
        this.modeText = 'Update';
        this.editData = data.item;
        this.mongoId = data.item._id;
        this.rId = data.item.ruleId;
        this.initEdit();
      } else {
        this.modeText = 'Create';
        this.editData = null;
        this.rId = null;
        this.mongoId = null;
        this.clearFor()
      }
    },
  },
  methods: {
    clearDecisionModel() {
      this.decisionAlertFlag = false;
    },
    emailChanged(e) {
      //console.log(e)
    },
    initEdit() {
      //console.log(this.editData);
      const data = this.editData;
      this.selectedForLevel = {
        levelName: 'Organization(s)',
        levelId: 1,
        value: ForTypes.ORG,
      };
      this.bindFor(data.arFor.forType);
      this.bindFrom(data.arFor.arFrom[0].fromType);
      this.bindChoose(data.arFor);
      this.bindConSett(data.arConditionSettings);
      this.bindCons(data.arConditions);
      this.bindSett(data.arSettings, data.ruleName);
      this.bindActions(data.arActions);
    },
    bindActions(e) {
      if (e.actionLimit != ActionFrequencies.EVERY_DAY && e.actionLimit != null) {
        this.limitActionFreq = true;
      } else {
        this.limitActionFreq = false;
      }
      this.selectedActionFreq = e.actionLimit? e.actionLimit: ActionFrequencies.EVERY_DAY;

      this.aiItems = [];
      e.actionList.map((x) => {
        let aiTypeObjTemp;
        let selActionTemp = 0;
        let aiEmailTemp = null;
        let aiAddToTemp;
        let aiMatchTypeTemp;
        let aiCPTBidTypeTemp;
        let aiCPTBidValueTemp = 0.01;
        let externalEmailIdTemp = null;
        let aiOperationTemp;
        let aiOperatorsTemp;
        let aiValueTemp;
        let aiCampaignsTemp;
        let aiAdgroupsTemp;
        let aiExtCheckedTemp = false;
        switch (x.aiType) {
          case AITypes.EMAIL_NOTIF:
            aiTypeObjTemp = {
              action: 'Email Notification',
              id: 1,
              value: AITypes.EMAIL_NOTIF,
            };
            selActionTemp = 1;
            aiEmailTemp = x.aiEmail?.map((e) => {
              return { Email: e };
            });
            if (aiEmailTemp) {
              const ext = this.emailList.filter(
                (e) => e.Email == aiEmailTemp[0].Email
              );
              if (ext.length > 0) {
                aiExtCheckedTemp = false;
                externalEmailIdTemp = null;
              } else {
                aiExtCheckedTemp = true;
                //externalEmailIdTemp = x.aiEmail.join()
                externalEmailIdTemp = x.aiEmail;
              }
            }
            break;
          case AITypes.SLACK_NOTIF:
            aiTypeObjTemp = {
              action: 'Slack Notification',
              id: 2,
              value: AITypes.SLACK_NOTIF,
            };
            selActionTemp = 2;
            break;
          case AITypes.PAUSE:
            aiTypeObjTemp = { action: 'Pause', id: 3, value: AITypes.PAUSE };
            selActionTemp = 3;
            break;
          case AITypes.CHANGE_DAILY_CAP:
            aiTypeObjTemp = {
              action: 'Change Daily Cap',
              id: 4,
              value: AITypes.CHANGE_DAILY_CAP,
            };
            selActionTemp = 4;
            break;
          case AITypes.CHANGE_BID:
            aiTypeObjTemp = {
              action: 'Change Bid',
              id: 5,
              value: AITypes.CHANGE_BID,
            };
            selActionTemp = 5;
            break;
          case AITypes.CHANGE_BUDGET:
            aiTypeObjTemp = {
              action: 'Change Budget',
              id: 6,
              value: AITypes.CHANGE_BUDGET,
            };
            selActionTemp = 6;
            break;
          case AITypes.ADD_AS_KEYWORD:
            aiTypeObjTemp = {
              action: 'Add As Keyword',
              id: 7,
              value: AITypes.ADD_AS_KEYWORD,
            };
            selActionTemp = 7;
            this.selAdGroups = x.aiAdgroups
            break;
          case AITypes.ADD_AS_NEG_KEYWORD:
            aiTypeObjTemp = {
              action: 'Add As Negative Keyword',
              id: 8,
              value: AITypes.ADD_AS_NEG_KEYWORD,
            };
            selActionTemp = 8;
            this.selCampaigns = x.aiCampaigns
            break;
          default:
            break;
        }
        this.aiItems.push({
          aiTypeObj: aiTypeObjTemp,
          aiType: null,
          selAction: selActionTemp,
          aiEmail: aiEmailTemp,
          aiSlack: x.aiSlack,
          aiAddTo: x.aiAddTo,
          aiMatchType: x.aiMatchType,
          aiCPTBidType: x.aiCPTBidType,
          aiCPTBidValue: parseFloat(x.aiCPTBidValue),
          externalEmailId: externalEmailIdTemp,
          aiOperation: x.aiOperation,
          aiOperators: x.aiOperators,
          aiValue: x.aiValue,
          aiCampaigns: x.aiCampaigns,
          aiAdgroups: x.aiAdgroups,
          aiExtChecked: aiExtCheckedTemp,
        });
      });
      const usedSoFar = this.repopulateActions();
    },
    bindSett(e, name) {
      this.selectedCheckFreq = e.checkFrequency;
      this.checkFreqCustomHr = e.customHour;
      this.selectedExcludeDays = e.excludeDays;
      this.ruleName = name;
      if (e.excludeDays?.length > 0) {
        this.excludeDaysCheck = true;
      } else {
        this.excludeDaysCheck = false;
      }
    },
    bindCons(e) {
      this.acItems = [];
      e.map((x) => {
        let ciFieldTemp = {};
        let selConTemp = 0;
        let selTypeTemp = 0;
        switch (x.ciField) {
          case ACFields.SPEND:
            ciFieldTemp = {
              field: 'Spend',
              id: 1,
              type: 1,
              value: ACFields.SPEND,
            };
            selConTemp = 1;
            selTypeTemp = 1;
            break;
          case ACFields.TAPS:
            ciFieldTemp = {
              field: 'Taps',
              id: 12,
              type: 2,
              value: ACFields.TAPS,
            };
            selConTemp = 12;
            selTypeTemp = 2;
            break;
          case ACFields.INSTALLS:
            selConTemp = 2;
            selTypeTemp = 2;
            ciFieldTemp = {
              field: 'Installs',
              id: 2,
              type: 2,
              value: ACFields.INSTALLS,
            };
            break;
          case ACFields.IMPRESSIONS:
            selConTemp = 3;
            selTypeTemp = 2;
            ciFieldTemp = {
              field: 'Impressions',
              id: 3,
              type: 2,
              value: ACFields.IMPRESSIONS,
            };
            break;
          case ACFields.CPA:
            selConTemp = 4;
            selTypeTemp = 1;
            ciFieldTemp = { field: 'CPA', id: 4, type: 1, value: ACFields.CPA };
            break;
          case ACFields.CPT:
            selConTemp = 5;
            selTypeTemp = 1;
            ciFieldTemp = { field: 'CPT', id: 5, type: 1, value: ACFields.CPT };
            break;
          case ACFields.TTR:
            selConTemp = 6;
            selTypeTemp = 2;
            ciFieldTemp = { field: 'TTR', id: 6, type: 2, value: ACFields.TTR };
            break;
          case ACFields.LAT_ON_INSTALLS:
            selConTemp = 7;
            selTypeTemp = 2;
            ciFieldTemp = {
              field: 'Lat On Installs',
              id: 7,
              type: 2,
              value: ACFields.LAT_ON_INSTALLS,
            };
            break;
          case ACFields.LAT_OFF_INSTALLS:
            selConTemp = 8;
            selTypeTemp = 2;
            ciFieldTemp = {
              field: 'Lat Off Installs',
              id: 8,
              type: 2,
              value: ACFields.LAT_OFF_INSTALLS,
            };
            break;
          case ACFields.CONVERSION_RATE:
            selConTemp = 9;
            selTypeTemp = 2;
            ciFieldTemp = {
              field: 'Conversion Rate',
              id: 9,
              type: 2,
              value: ACFields.CONVERSION_RATE,
            };
            break;
          case ACFields.NEW_DOWNLOADS:
            selConTemp = 10;
            selTypeTemp = 2;
            ciFieldTemp = {
              field: 'New Downloads',
              id: 10,
              type: 2,
              value: ACFields.NEW_DOWNLOADS,
            };
            break;
          case ACFields.RE_DOWNLOADS:
            selConTemp = 11;
            selTypeTemp = 2;
            ciFieldTemp = {
              field: 'Re Downloads',
              id: 11,
              type: 2,
              value: ACFields.RE_DOWNLOADS,
            };
            break;
          default:
            break;
        }
        this.acItems.push({
          ciField: ciFieldTemp,
          selCon: selConTemp,
          selType: selTypeTemp,
          ciOperation: x.ciOperation,
          ciOperator: x.ciOperator,
          ciToValue: parseFloat(x.ciToValue),
          ciFromValue: parseFloat(x.ciFromValue),
          ciValue: parseFloat(x.ciValue),
        });
      });
      const usedSoFar = this.repopulateCons();
    },
    bindConSett(e) {
      this.selectedAndOrtype = e.ciAndOr;
      this.selectedciTimeRange = e.ciTimeRange;
      this.ciTimeRangeCustomHr = e.ciCustomHour ? e.ciCustomHour : 0;
    },
    bindChoose(e) {
      switch (this.selectedFromLevel.value) {
        case FromTypes.ORG:
          this.selectedOrgs = e.arFrom[0].fromValues.map((e) => {
            const o = {
              orgId : e.fromValId,
              orgName: e.fromValName
            }
            return o
          });
          break;
        case FromTypes.APP:
          let r = [];
          for (let index = 0; index < e.arFrom.length; index++) {
            const f = e.arFrom[index];
            f.fromValues.map((x) => {
              const t = {
                adamId: x.fromValId,
                appName: x.fromValName,
                orgId: f.orgId,
              };
              r.push(t);
            });
          }
          this.apps = r;
          break;
        case FromTypes.CAMPAIGN:
          this.campsFromChose = e.arFrom;
          let p = [];
          for (let index = 0; index < this.campsFromChose.length; index++) {
            const e = this.campsFromChose[index];
            e.fromValues.map((x) => {
              const t = {
                id: x.fromValId,
                name: x.fromValName,
                orgId: e.orgId,
              };
              p.push(t);
            });
          }
          this.campaigns = p;
          break;
        case FromTypes.ADGROUP:
          this.adGroupsFromChose = e.arFrom;
          let q = [];
          for (let index = 0; index < this.adGroupsFromChose.length; index++) {
            const e = this.adGroupsFromChose[index];
            e.fromValues.map((x) => {
              const t = {
                id: x.fromValId,
                name: x.fromValName,
                orgId: e.orgId,
                campaignId: e.campaignId,
              };
              q.push(t);
            });
          }
          this.adGroups = q;
          break;
        case FromTypes.KEYWORD:
          this.keysFromChose = e.arFrom;
          let s = [];
          for (let index = 0; index < this.keysFromChose.length; index++) {
            const e = this.keysFromChose[index];
            e.fromValues.map((x) => {
              const t = {
                id: x.fromValId,
                name: x.fromValName,
                orgId: e.orgId,
                campId: e.campaignId,
              };
              s.push(t);
            });
          }
          this.keywords = s;
          break;
        default:
          break;
      }
    },
    bindFor(e) {
      switch (e) {
        case ForTypes.ORG:
          this.selectedForLevel = {
            levelName: 'Organization(s)',
            levelId: 1,
            value: ForTypes.ORG,
          };
          this.levelChanged(this.selectedForLevel);
          break;
        case ForTypes.APP:
          this.selectedForLevel = {
            levelName: 'Application(s)',
            levelId: 2,
            value: ForTypes.APP,
          };
          this.levelChanged(this.selectedForLevel);
          break;
        case ForTypes.CAMPAIGN:
          this.selectedForLevel = {
            levelName: 'Campaign(s)',
            levelId: 3,
            value: ForTypes.CAMPAIGN,
          };
          this.levelChanged(this.selectedForLevel);
          break;
        case ForTypes.ADGROUP:
          this.selectedForLevel = {
            levelName: 'Ad Group(s)',
            levelId: 4,
            value: ForTypes.ADGROUP,
          };
          this.levelChanged(this.selectedForLevel);
          break;
        case ForTypes.KEYWORD:
          this.selectedForLevel = {
            levelName: 'Keyword(s)',
            levelId: 5,
            value: ForTypes.KEYWORD,
          };
          this.levelChanged(this.selectedForLevel);
          break;
        case ForTypes.SEARCHTERM:
          this.selectedForLevel = {
            levelName: 'Search Term(s)',
            levelId: 6,
            value: ForTypes.SEARCHTERM,
          };
          this.levelChanged(this.selectedForLevel);
          break;
        default:
          this.selectedForLevel = {
            levelName: 'Select Level',
            levelId: 0,
            value: '',
          };
          break;
      }
    },
    bindFrom(e) {
      switch (e) {
        case FromTypes.ORG:
          this.selectedFromLevel = {
            levelName: 'Organization(s)',
            levelId: 1,
            value: FromTypes.ORG,
          };
          break;
        case FromTypes.APP:
          this.selectedFromLevel = {
            levelName: 'Application(s)',
            levelId: 2,
            value: FromTypes.APP,
          };
          break;
        case FromTypes.CAMPAIGN:
          this.selectedFromLevel = {
            levelName: 'Campaign(s)',
            levelId: 3,
            value: FromTypes.CAMPAIGN,
          };
          break;
        case FromTypes.ADGROUP:
          this.selectedFromLevel = {
            levelName: 'Ad Group(s)',
            levelId: 4,
            value: FromTypes.ADGROUP,
          };
          break;
        case FromTypes.KEYWORD:
          this.selectedFromLevel = {
            levelName: 'Keyword(s)',
            levelId: 5,
            value: FromTypes.KEYWORD,
          };
          break;
        default:
          this.selectedFromLevel = {
            levelName: 'Select Level',
            levelId: 0,
            value: '',
          };
          break;
      }
    },
    orgChanged() {
      //console.log('orgChanged', this.selectedOrgs)
      this.step = 1
    },
    checkEmails(i) {
      //const emails = this.aiItems[i].externalEmailId.split(',')
      const emails = this.aiItems[i].externalEmailId;
      emails.forEach((element) => {
        if (/.+@.+\..+/.test(element)) {
          this.improperEmail = false;
        } else {
          this.improperEmail = true;
        }
      });
    },
    extEmailChecked(i) {
      this.aiItems[i].aiEmail = [];
    },
    toggle(i) {
      this.selectAllEmails = ~this.selectAllEmails;
      if (this.selectAllEmails) {
        this.aiItems[i].aiEmail = this.emailList;
        this.selectAllIcon = 'mdi-checkbox-multiple-marked';
      } else {
        this.aiItems[i].aiEmail = null;
        this.selectAllIcon = 'mdi-crop-square';
      }
    },
    toggleSlack(i) {
      this.selectAllSlacks = ~this.selectAllSlacks;
      if (this.selectAllSlacks) {
        this.aiItems[i].aiSlack = this.slackChannelList.map(
          (e) => e.IntegrationID
        );
        this.selectAllSlackIcon = 'mdi-checkbox-multiple-marked';
      } else {
        this.aiItems[i].aiSlack = null;
        this.selectAllSlackIcon = 'mdi-crop-square';
      }
    },
    removeAppfromChoose(idx) {
      this.apps = this.apps.filter((x, y) => y != idx);
    },
    removeCampfromChoose(idx) {
      this.campaigns = this.campaigns.filter((x, y) => y != idx);
      //this.campsFromChose = this.campaigns.map(e => e.id)
      const tempOrgs = [];
      const campArr = [];
      for (let index = 0; index < this.campaigns.length; index++) {
        const element = this.campaigns[index];
        const orgId = element.orgId;
        const campId = element.id;
        const campName = element.name;

        if (tempOrgs.includes(orgId)) {
          const i = tempOrgs.indexOf(orgId);
          campArr[i].fromValues.push({
            fromValId: campId,
            fromValName: campName,
          });
        } else {
          tempOrgs.push(orgId);
          let tempCamp = {
            fromType: this.selectedFromLevel.value,
            orgId: orgId,
            fromValues: [{ fromValId: campId, fromValName: campName }],
          };
          campArr.push(tempCamp);
        }
      }
      //console.log(campArr);
      this.campsFromChose = campArr;
    },
    removeCampfromKeyword(idx, index) {
      this.selCampaigns = this.selCampaigns.filter((x, y) => y != idx);
      //this.aiItems[index].aiCampaigns = this.selCampaigns.map(e => e.id)
      this.aiItems[index].aiCampaigns = this.selCampaigns;
    },
    removeAdfromChoose(idx) {
      this.adGroups = this.adGroups.filter((x, y) => y != idx);
      //this.adGroupsFromChose = this.adGroups.map(e => e.id)
      const tempOrgs = [];
      const adArr = [];
      for (let index = 0; index < this.adGroups.length; index++) {
        const element = this.adGroups[index];
        const orgId = element.orgId;
        const adId = element.id;
        const adName = element.name;
        const campId = element.campaignId;

        if (tempOrgs.includes(campId)) {
          const i = tempOrgs.indexOf(campId);
          adArr[i].fromValues.push({ fromValId: adId, fromValName: adName });
        } else {
          tempOrgs.push(campId);
          let tempAd = {
            fromType: this.selectedFromLevel.value,
            orgId: orgId,
            fromValues: [{ fromValId: adId, fromValName: adName }],
            campaignId: campId,
          };
          adArr.push(tempAd);
        }
      }
      //console.log(adArr);
      this.adGroupsFromChose = adArr;
    },
    removeAdfromKeyword(idx, index) {
      this.selAdGroups = this.selAdGroups.filter((x, y) => y != idx);
      //this.aiItems[index].aiAdgroups = this.selAdGroups.map(e => e.id)
      this.aiItems[index].aiAdgroups = this.selAdGroups;
    },
    removeKeyfromChoose(idx) {
      this.keywords = this.keywords.filter((x, y) => y != idx);
      const tempOrgs = [];
      const adArr = [];
      for (let index = 0; index < this.keywords.length; index++) {
        const element = this.keywords[index];
        const orgId = element.orgId;
        const adId = element.id;
        const adName = element.name;
        const campId = element.campId;

        if (tempOrgs.includes(campId)) {
          const i = tempOrgs.indexOf(campId);
          adArr[i].fromValues.push({ fromValId: adId, fromValName: adName });
        } else {
          tempOrgs.push(campId);
          let tempAd = {
            fromType: this.selectedFromLevel.value,
            orgId: orgId,
            fromValues: [{ fromValId: adId, fromValName: adName }],
            campaignId: campId,
          };
          adArr.push(tempAd);
        }
      }
      this.keysFromChose = adArr;
    },
    choosePop() {
      //console.log(this.selectedFromLevel.levelId)
      const from = this.selectedFromLevel.levelId;
      if (from == 2) {
        this.openAppsPop();
      } else if (from == 3) {
        this.openCampaignPop();
      } else if (from == 4) {
        this.openAdGroupPop();
      } else if (from == 5) {
        this.openKeysPop();
      } else {
        console.log();
      }
      this.step = 1
    },
    openKeysPop() {
      this.toSelectKeys = {
        now: Date.now(),
      };
    },
    openAppsPop() {
      this.toSelectApps = {
        now: Date.now(),
      };
    },
    openCampaignPop() {
      this.selectCampaign = {
        now: Date.now(),
      };
    },
    openAdGroupPop() {
      this.selectAdgroupForChoose = {
        now: Date.now(),
      };
    },
    openCampaign(index) {
      this.selectCampaignForKeywords = {
        now: Date.now(),
        arIndex: index,
      };
    },
    openAdGroup(index) {
      this.selectAdgroupForKeywords = {
        now: Date.now(),
        arIndex: index,
      };
    },
    appsSelectedForChoose(e) {
      //console.log(e)
      this.apps = e;
    },
    adgroupsSelectedForKeyword(appsWithCampaigns) {
      //console.log(appsWithCampaigns);
      const adgroups = this.getNested(appsWithCampaigns?.data, 'ADGROUP');
      //console.log(adgroups)
      //this.aiItems[appsWithCampaigns.index].aiAdgroups = adgroups.map(x => x.id)
      //console.log(this.aiItems[appsWithCampaigns.index].aiAdgroups)
      this.selAdGroups = adgroups.map((x) => {
        const t = {
          addToName: x.name,
          addToId: x.id,
        };
        return t;
      });
      this.aiItems[appsWithCampaigns.index].aiAdgroups = this.selAdGroups;
    },
    campaignSelectedForKeyword(appsWithCampaigns) {
      //console.log(appsWithCampaigns);
      const campaigns = this.getNested(appsWithCampaigns.data, 'CAMPAIGN');
      //console.log(campaigns)
      //this.aiItems[appsWithCampaigns.index].aiCampaigns = campaigns.map(x => x.id)
      //console.log(this.aiItems[appsWithCampaigns.index].aiCampaigns)
      this.selCampaigns = campaigns.map((x) => {
        const t = {
          addToName: x.name,
          addToId: x.id,
        };
        return t;
      });
      this.aiItems[appsWithCampaigns.index].aiCampaigns = this.selCampaigns;
    },
    adgroupsSelectedForChoose(appsWithCampaigns) {
      //console.log(appsWithCampaigns);
      const adgroups = this.getNested(appsWithCampaigns?.data, 'ADGROUP');
      //console.log(adgroups)
      // this.adGroups = adgroups.map(x => {
      //   const t = {
      //     name: x.name,
      //     id: x.id
      //   }
      //   return t
      // })
      this.adGroups = adgroups;
      const tempOrgs = [];
      const adArr = [];
      for (let index = 0; index < adgroups.length; index++) {
        const element = adgroups[index];
        const orgId = element.orgId;
        const adId = element.id;
        const adName = element.name;
        const campId = element.campaignId;

        if (tempOrgs.includes(campId)) {
          const i = tempOrgs.indexOf(campId);
          adArr[i].fromValues.push({ fromValId: adId, fromValName: adName });
        } else {
          tempOrgs.push(campId);
          let tempAd = {
            fromType: this.selectedFromLevel.value,
            orgId: orgId,
            fromValues: [{ fromValId: adId, fromValName: adName }],
            campaignId: campId,
          };
          adArr.push(tempAd);
        }
      }
      //console.log(adArr);
      this.adGroupsFromChose = adArr;
    },
    campaignSelectedForChoose(appsWithCampaigns) {
      //console.log(appsWithCampaigns);
      const campaigns = this.getNested(appsWithCampaigns, 'CAMPAIGN');
      //console.log(campaigns)
      // this.campaigns = campaigns.map(x => {
      //   const t = {
      //     name: x.name,
      //     id: x.id
      //   }
      //   return t
      // })
      //console.log(this.campaigns)
      //this.chosedData = campaigns
      //this.orgs = Array.from(new Set(campaigns.map((item) => item.orgId)))
      //console.log(this.orgs)

      this.campaigns = campaigns;

      const tempOrgs = [];
      const campArr = [];
      for (let index = 0; index < campaigns.length; index++) {
        const element = campaigns[index];
        const orgId = element.orgId;
        const campId = element.id;
        const campName = element.name;

        if (tempOrgs.includes(orgId)) {
          const i = tempOrgs.indexOf(orgId);
          campArr[i].fromValues.push({
            fromValId: campId,
            fromValName: campName,
          });
        } else {
          tempOrgs.push(orgId);
          let tempCamp = {
            fromType: this.selectedFromLevel.value,
            orgId: orgId,
            fromValues: [{ fromValId: campId, fromValName: campName }],
          };
          campArr.push(tempCamp);
        }
      }
      //console.log(campArr);
      this.campsFromChose = campArr;
    },
    keysSelectedForChoose(data) {
      this.keywords = data;
      const tempOrgs = [];
      const adArr = [];
      for (let index = 0; index < this.keywords.length; index++) {
        const element = this.keywords[index];
        const orgId = element.orgId;
        const adId = element.id;
        const adName = element.name;
        const campId = element.campId;

        if (tempOrgs.includes(campId)) {
          const i = tempOrgs.indexOf(campId);
          adArr[i].fromValues.push({ fromValId: adId, fromValName: adName });
        } else {
          tempOrgs.push(campId);
          let tempAd = {
            fromType: this.selectedFromLevel.value,
            orgId: orgId,
            fromValues: [{ fromValId: adId, fromValName: adName }],
            campaignId: campId,
          };
          adArr.push(tempAd);
        }
      }
      this.keysFromChose = adArr;
    },
    getNested(appsWithCampaigns, level: 'APP' | 'CAMPAIGN' | 'ADGROUP') {
      return level === 'APP'
        ? appsWithCampaigns
        : [].concat(
            ...appsWithCampaigns.map((app) =>
              level === 'CAMPAIGN'
                ? app.campaigns
                : [].concat(
                    ...app.campaigns.map((campaign) => campaign.adgroups)
                  )
            )
          );
    },
    validateRange(){
      this.$refs.aiConditionForm.validate();
    },
    toStep2() {
      this.$refs.aiConditionForm.validate();
      if (!this.aiConditionFormvalid) {
        return;
      }
      this.step = 2;
    },
    toStep3() {
      this.$refs.aiItemForm.validate();
      if (!this.aiItemFormvalid) {
        return;
      }

      this.step = 3;
    },
    clearFor() {
      if(this.$refs.aiConditionForm) this.$refs.aiConditionForm.reset();
      if(this.$refs.aiItemForm) this.$refs.aiItemForm.reset();
      if(this.$refs.ruleSettForm) this.$refs.ruleSettForm.reset();
      this.selectedForLevel = {
        levelName: 'Select Level',
        levelId: 0,
        value: '',
      };
      this.selectedFromLevel = { levelName: 'Select', levelId: 0, value: '' };
      this.selectedAndOrtype =  CIAndORTypes.AND
      this.selectedciTimeRange = CITimeRanges.LAST_30_DAYS
      this.selectedActionFreq = ActionFrequencies.EVERY_DAY
      this.selectedCheckFreq = RuleCheckFrequencies.EVERY_DAY
      this.limitActionFreq = false
      this.selectAllSlackIcon = 'mdi-crop-square';
      this.selectAllIcon = 'mdi-crop-square';
      this.campsFromChose = [];
      this.adGroupsFromChose = [];
      this.selectedOrgs = [];
      this.campaigns = [];
      this.apps = [];
      this.adGroups = [];
      this.fromLevels = [];
      this.initAiItem;
      (this.acItems = [
        {
          ciField: null,
          selCon: 0,
          selType: 0,
          ciOperation: null,
          ciOperator: CIOperators.DOLLAR_AMOUNT,
          ciToValue: null,
          ciFromValue: null,
          ciValue: null,
          ciFieldList: this.allConItems,
        },
      ]),
      this.step = 1
    },
    askConfirm() {
      this.$refs.ruleSettForm.validate();
      if (!this.ruleSettFormvalid) {
        return;
      }
      this.decisionAlertFlag = true;
      this.decisionAlertData = {
        from: 'createRule',
        now: Date.now(),
      };
    },
    decisionOkAction(event) {
      this.decisionAlertFlag = false;
      this.saveRule();
    },
    saveRule() {
      this.$refs.ruleSettForm.validate();
      if (!this.ruleSettFormvalid) {
        return;
      }

      const tempAiItems = this.aiItems.map((each) => {
        if (each.aiExtChecked) {
          //const emails = each.externalEmailId.split(',')
          const emails = each.externalEmailId;
          each.aiEmail = [];
          emails.forEach((element) => {
            each.aiEmail.push({ Email: element });
          });
        }
        if (each.aiTypeObj.value == AITypes.EMAIL_NOTIF) {
          each.aiEmail = each.aiEmail.map((e) => e.Email);
        }
        each.aiType = each.aiTypeObj.value;
        each.aiCPTBidValue = each.aiCPTBidValue
          ? parseFloat(each.aiCPTBidValue)
          : null;
        each.aiValue = each.aiValue ? parseFloat(each.aiValue) : null;
        return each;
      });

      const finalAiItems = tempAiItems.map(
        ({
          selAction,
          externalEmailId,
          aiExtChecked,
          aiTypeObj,
          aiActionList,
          ...keepAttrs
        }) => {
          return keepAttrs;
        }
      );

      const arActions = {
        actionLimit: this.selectedActionFreq,
        actionList: finalAiItems,
      };

      const arSettings = {
        checkFrequency: this.selectedCheckFreq,
        excludeDays: this.selectedExcludeDays,
        customHour: this.checkFreqCustomHr
          ? parseFloat(this.checkFreqCustomHr)
          : null,
      };

      const arConditionSettings = {
        ciTimeRange: this.selectedciTimeRange,
        ciCustomHour: this.ciTimeRangeCustomHr
          ? parseFloat(this.ciTimeRangeCustomHr)
          : null,
        ciAndOr: this.selectedAndOrtype,
      };

      //using destructuring syntax, remove selCon, selType keys(attr) from each object in acItems array
      //keep each obj with remaining attributes
      //then, on each of the the result obj, change ciField to ciField.value
      const arConditionsReduced = this.acItems.map(
        ({ selCon, selType, ciFieldList, ...keepAttrs }) => {
          keepAttrs.ciField = keepAttrs.ciField.value;
          keepAttrs.ciValue = keepAttrs.ciValue
            ? parseFloat(keepAttrs.ciValue)
            : null;
          keepAttrs.ciToValue = keepAttrs.ciToValue
            ? parseFloat(keepAttrs.ciToValue)
            : null;
          keepAttrs.ciFromValue = keepAttrs.ciFromValue
            ? parseFloat(keepAttrs.ciFromValue)
            : null;
          return keepAttrs;
        }
      );

      const arFor = this.buildRuleObj();

      let rId = uuidv4();
      if (this.mode == 2) {
        rId = this.rId;
      }

      //console.log(arFor);
      const finalObj = {
        arFor: arFor,
        arStatus: ARStatus.RUNNING,
        arActions: arActions,
        arSettings: arSettings,
        arConditions: arConditionsReduced,
        arConditionSettings: arConditionSettings,
        ruleName: this.ruleName,
        startNow: false,
        ruleId: rId,
      };
      //console.log(finalObj);
      if (this.mode == 1) {
        this.createRule(finalObj);
      } else if (this.mode == 2) {
        this.updateRule(finalObj, this.mongoId);
      } else {
      }
    },
    updateRule(ruleReq, id) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: UPDATE_AR_RULE,
          variables: {
            ruleId: id,
            ruleReq: ruleReq,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success('Rule updated successfully');
          this.clearFor();
          this.close();
          this.$emit('fromCreateRule', true);
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error('Rule update failed. Please try later');
          this.close();
          this.$emit('fromCreateRule', false);
        });
    },
    createRule(ruleReq) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .mutate({
          mutation: CREATE_AR_RULE,
          variables: {
            ruleReq: ruleReq,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success('Rule created successfully');
          this.clearFor();
          this.close();
          this.$emit('fromCreateRule', true);
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error('Rule creation failed. Please try later');
          this.close();
          this.$emit('fromCreateRule', false);
        });
    },
    close() {
      //console.log('Create Rule Closed');
      // this.selectedForLevel = { levelName: 'Select Level', levelId: 0, value: '' }
      // this.selectedFromLevel = { levelName: 'Select', levelId: 0, value: '' }
      // this.campsFromChose = []
      // this.adGroupsFromChose = []
      // this.selectedOrgs = []
      // this.campaigns = []
      // this.apps = []
      // this.adGroups = []
      // if(this.$refs.aiConditionForm) this.$refs.aiConditionForm.reset()
      // if(this.$refs.aiItemForm) this.$refs.aiItemForm.reset()
      // if(this.$refs.ruleSettForm) this.$refs.ruleSettForm.reset()
      // this.step = 1
      this.showCreateAR = false;
    },
    levelChanged(e) {
      //console.log(e.levelId);
      this.selectedFromLevel = { levelName: 'Select', levelId: 0, value: '' },
      this.selectedOrgs = []
      this.apps = []
      this.campaigns = []
      this.adGroups = []
      this.keywords = []
      this.fromLevels = [];
      this.initAiItem();
      (this.acItems = [
        {
          ciField: null,
          selCon: 0,
          selType: 0,
          ciOperation: null,
          ciOperator: CIOperators.DOLLAR_AMOUNT,
          ciToValue: null,
          ciFromValue: null,
          ciValue: null,
          ciFieldList: this.allConItems,
        },
      ])
      for (let index = 0; index <= e.levelId; index++) {
        const element = this.AllFromLevels[index];
        if (e.levelId == 6 && index == 5) break;
        this.fromLevels.push(element);
      }
      this.AllActionItems = [];
      this.AllActionItems = [
        { action: 'Email Notification', id: 1, value: AITypes.EMAIL_NOTIF },
        { action: 'Slack Notification', id: 2, value: AITypes.SLACK_NOTIF },
        { action: 'Pause', id: 3, value: AITypes.PAUSE },
        { action: 'Change Daily Cap', id: 4, value: AITypes.CHANGE_DAILY_CAP },
        { action: 'Change Bid', id: 5, value: AITypes.CHANGE_BID },
        { action: 'Change Budget', id: 6, value: AITypes.CHANGE_BUDGET },
        { action: 'Add As Keyword', id: 7, value: AITypes.ADD_AS_KEYWORD },
        {
          action: 'Add As Negative Keyword',
          id: 8,
          value: AITypes.ADD_AS_NEG_KEYWORD,
        },
      ];
      if (e.levelId == 1 || e.levelId == 2) {
        this.AllActionItems = [
          { action: 'Email Notification', id: 1, value: AITypes.EMAIL_NOTIF },
          { action: 'Slack Notification', id: 2, value: AITypes.SLACK_NOTIF },
        ];
      } else if (e.levelId == 3) {
        this.AllActionItems = [
          { action: 'Email Notification', id: 1, value: AITypes.EMAIL_NOTIF },
          { action: 'Slack Notification', id: 2, value: AITypes.SLACK_NOTIF },
          { action: 'Pause', id: 3, value: AITypes.PAUSE },
          {
            action: 'Change Daily Cap',
            id: 4,
            value: AITypes.CHANGE_DAILY_CAP,
          },
          { action: 'Change Budget', id: 6, value: AITypes.CHANGE_BUDGET },
        ];
      } else if (e.levelId == 4) {
        this.AllActionItems = [
          { action: 'Email Notification', id: 1, value: AITypes.EMAIL_NOTIF },
          { action: 'Slack Notification', id: 2, value: AITypes.SLACK_NOTIF },
          { action: 'Pause', id: 3, value: AITypes.PAUSE },
          { action: 'Change Bid', id: 5, value: AITypes.CHANGE_BID },
        ];
      } else if (e.levelId == 5) {
        this.AllActionItems = [
          { action: 'Email Notification', id: 1, value: AITypes.EMAIL_NOTIF },
          { action: 'Slack Notification', id: 2, value: AITypes.SLACK_NOTIF },
          { action: 'Pause', id: 3, value: AITypes.PAUSE },
          { action: 'Change Bid', id: 5, value: AITypes.CHANGE_BID },
          {
            action: 'Add As Negative Keyword',
            id: 8,
            value: AITypes.ADD_AS_NEG_KEYWORD,
          },
        ];
      } else if (e.levelId == 6) {
        this.AllActionItems = [
          { action: 'Email Notification', id: 1, value: AITypes.EMAIL_NOTIF },
          { action: 'Slack Notification', id: 2, value: AITypes.SLACK_NOTIF },
          {
            action: 'Add As Negative Keyword',
            id: 8,
            value: AITypes.ADD_AS_NEG_KEYWORD,
          },
          { action: 'Add As Keyword', id: 7, value: AITypes.ADD_AS_KEYWORD },
        ];
      }
      this.aiItems[0].aiActionList = this.AllActionItems;
      this.step = 1
    },
    fromChanged(e) {
      this.selectedOrgs = [];
      this.campaigns = [];
      this.apps = [];
      this.adGroups = [];
      this.keywords = []
      this.step = 1
    },
    stepChanged(e) {
      //console.log();
    },
    actionItemChanged(e, index) {
      this.aiItems[index].selAction = e.id;

      const usedSoFar = this.repopulateActions();

      this.aiItems[index].aiEmail = null;
      this.aiItems[index].aiSlack = null;
      this.aiItems[index].aiAddTo = null;
      this.aiItems[index].aiMatchType = null;
      this.aiItems[index].aiCPTBidType = null;
      this.aiItems[index].aiCPTBidValue = 0.01;
      this.aiItems[index].externalEmailId = null;
      this.aiItems[index].aiOperation = null;
      this.aiItems[index].aiOperators = null;
      this.aiItems[index].aiValue = null;
      this.aiItems[index].aiCampaigns = null;
      this.aiItems[index].aiAdgroups = null;
      this.aiItems[index].aiExtChecked = false;
    },
    deleteActionItem(index) {
      const newArr = this.aiItems.filter((x, i) => i != index);
      this.aiItems = newArr;

      const usedSoFar = this.repopulateActions();
    },
    addActionItem() {
      const usedSoFar = this.repopulateActions();

      let t;
      if (this.aiItems.length == 0) {
        t = this.AllActionItems;
      } else {
        t = this.AllActionItems.filter((x) => {
          if (!usedSoFar.includes(x.id)) return x;
        });
        t.push({
          action: 'Email Notification',
          id: 1,
          value: AITypes.EMAIL_NOTIF,
        });
      }

      this.aiItems.push({
        aiTypeObj: null,
        aiType: null,
        selAction: 0,
        aiEmail: null,
        aiSlack: null,
        aiAddTo: null,
        aiMatchType: null,
        aiCPTBidType: null,
        aiCPTBidValue: 0.01,
        externalEmailId: null,
        aiOperation: null,
        aiOperators: null,
        aiValue: null,
        aiCampaigns: null,
        aiAdgroups: null,
        aiExtChecked: false,
        aiActionList: t,
      });
    },
    repopulateActions() {
      let usedSoFar = [];
      for (let index = 0; index < this.aiItems.length; index++) {
        const element = this.aiItems[index];
        usedSoFar.push(element.selAction);

        if (this.selectedForLevel.value == ForTypes.SEARCHTERM) {
          if (element.selAction == 7) usedSoFar.push(8);
          if (element.selAction == 8) usedSoFar.push(7);
        }

        if (this.selectedForLevel.value == ForTypes.CAMPAIGN) {
          if (
            element.selAction == 3 ||
            element.selAction == 4 ||
            element.selAction == 6
          ) {
            usedSoFar.push(3);
            usedSoFar.push(4);
            usedSoFar.push(6);
          }
        }

        if (this.selectedForLevel.value == ForTypes.ADGROUP) {
          if (element.selAction == 3 || element.selAction == 5) {
            usedSoFar.push(3);
            usedSoFar.push(5);
          }
        }

        if (this.selectedForLevel.value == ForTypes.KEYWORD) {
          if (element.selAction == 3) {
            usedSoFar.push(8);
            usedSoFar.push(5);
          }
          if (element.selAction == 5) {
            usedSoFar.push(3);
          }
          if (element.selAction == 8) {
            usedSoFar.push(3);
          }
        }
      }

      for (let index = 0; index < this.aiItems.length; index++) {
        const element = this.aiItems[index];
        const sa = element.selAction;
        element.aiActionList = this.AllActionItems.filter((x) => {
          if (!usedSoFar.includes(x.id) || sa == x.id) return x;
        });
        element.aiActionList.push({
          action: 'Email Notification',
          id: 1,
          value: AITypes.EMAIL_NOTIF,
        });
      }

      return usedSoFar;
    },
    buildRuleObj() {
      let arFor = {
        forType: this.selectedForLevel.value,
        arFrom: [],
      };
      if (this.selectedFromLevel.levelId == 1) {
        let temp = {
          fromType: this.selectedFromLevel.value,
          orgId: 0,
          fromValues: [],
        };
        this.selectedOrgs.map((each) => {
          temp.fromValues.push({
            fromValId: each.orgId,
            fromValName: each.orgName,
          });
        });
        arFor.arFrom.push(temp);
      }
      if (this.selectedFromLevel.levelId == 2) {
        const tempOrgs = [];
        const campArr = [];
        for (let index = 0; index < this.apps.length; index++) {
          const element = this.apps[index];
          const orgId = element.orgId;
          const campId = element.adamId;
          const campName = element.appName;

          if (tempOrgs.includes(orgId)) {
            const i = tempOrgs.indexOf(orgId);
            campArr[i].fromValues.push({
              fromValId: campId,
              fromValName: campName,
            });
          } else {
            tempOrgs.push(orgId);
            let tempCamp = {
              fromType: this.selectedFromLevel.value,
              orgId: orgId,
              fromValues: [{ fromValId: campId, fromValName: campName }],
            };
            campArr.push(tempCamp);
          }
        }
        arFor.arFrom = campArr;
      }
      if (this.selectedFromLevel.levelId == 3) {
        arFor.arFrom = this.campsFromChose;
      }
      if (this.selectedFromLevel.levelId == 4) {
        arFor.arFrom = this.adGroupsFromChose;
      }
      if (this.selectedFromLevel.levelId == 5) {
        arFor.arFrom = this.keysFromChose;
      }
      return arFor;
    },
    limitActionFreqChanged(e) {
      //console.log('limitActionFreqChanged',e);
    },
    deleteConditionItem(index) {
      const newArr = this.acItems.filter((x, i) => i != index);
      this.acItems = newArr;

      const usedSoFar = this.repopulateCons();
    },
    addConditionItem() {
      const usedSoFar = this.repopulateCons();

      let t;
      if (this.acItems.length == 0) {
        t = this.allConItems;
      } else {
        t = this.allConItems.filter((x) => {
          if (!usedSoFar.includes(x.id)) return x;
        });
      }

      this.acItems.push({
        ciField: null,
        selCon: 0,
        selType: 0,
        ciOperation: null,
        ciOperator: CIOperators.DOLLAR_AMOUNT,
        ciToValue: null,
        ciFromValue: null,
        ciValue: null,
        ciFieldList: t,
      });
    },
    conditionItemChanged(e, index) {
      this.acItems[index].selCon = e.id;
      this.acItems[index].selType = e.type;

      const usedSoFar = this.repopulateCons();
    },
    repopulateCons() {
      let usedSoFar = [];
      for (let index = 0; index < this.acItems.length; index++) {
        const element = this.acItems[index];
        usedSoFar.push(element.selCon);
      }

      for (let index = 0; index < this.acItems.length; index++) {
        const element = this.acItems[index];
        const sa = element.selCon;
        element.ciFieldList = this.allConItems.filter((x) => {
          if (!usedSoFar.includes(x.id) || sa == x.id) return x;
        });
      }

      return usedSoFar;
    },
    initAiItem() {
      this.aiItems = [];
      this.addActionItem();
    },
    getSlacks() {
      //this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_SLACKS,
        })
        .then((res) => {
          //console.log(res.data);
          this.slackChannelList = res.data.getSlacks;
          //this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          //this.fullscreenLoader.hide();
        });
    },
    getEmails() {
      //this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_EMAILS,
        })
        .then((res) => {
          //console.log(res.data);
          this.emailList = res.data.getEmails ? res.data.getEmails : [];
          //this.emailList.push({Email: "External"})
          //this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          //this.fullscreenLoader.hide();
        });
    },
    getOrgs() {
      //this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_CAMPAIGN_GROUPS,
        })
        .then((res) => {
          //console.log(res.data);
          this.orgList = res.data.getCampaignGroups
            ? res.data.getCampaignGroups
            : [];
        })
        .catch((error) => {
          console.log(error);
          //this.fullscreenLoader.hide();
        });
    },
  },
  mounted() {
    this.slackChannelList = [];
    this.getSlacks();
    this.emailList = [];
    this.getEmails();
    this.getOrgs();
    this.initAiItem;
    (this.acItems = [
      {
        ciField: null,
        selCon: 0,
        selType: 0,
        ciOperation: null,
        ciOperator: CIOperators.DOLLAR_AMOUNT,
        ciToValue: null,
        ciFromValue: null,
        ciValue: null,
        ciFieldList: this.allConItems,
      },
    ]),
      (this.orgs = [12, 13]);
    this.apps = [];
    this.campaigns = [];
    this.adGroups = [];
    this.keywords = [];
  },
});
