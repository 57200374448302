

export enum RuleCheckFrequencies {
  HOURLY = 'HOURLY',
  EVERY_2_HRS = 'EVERY_2_HRS',
  EVERY_3_HRS = 'EVERY_3_HRS',
  EVERY_6_HRS = 'EVERY_6_HRS',
  EVERY_12_HRS = 'EVERY_12_HRS',
  EVERY_DAY = 'EVERY_DAY',
  EVERY_2ND_DAY = 'EVERY_2ND_DAY',
  EVERY_3RD_DAY = 'EVERY_3RD_DAY',
  EVERY_WEEK = 'EVERY_WEEK',
  CUSTOM = 'CUSTOM'
}

export enum ActionFrequencies {
  HOURLY = 'HOURLY',
  EVERY_2_HRS = 'EVERY_2_HRS',
  EVERY_3_HRS = 'EVERY_3_HRS',
  EVERY_6_HRS = 'EVERY_6_HRS',
  EVERY_12_HRS = 'EVERY_12_HRS',
  EVERY_DAY = 'EVERY_DAY',
  EVERY_2ND_DAY = 'EVERY_2ND_DAY',
  EVERY_3RD_DAY = 'EVERY_3RD_DAY',
  EVERY_WEEK = 'EVERY_WEEK',
}

export enum ARStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  RUNNING = 'RUNNING',
  ON_HOLD = 'ON_HOLD',
  PAUSED = 'PAUSED',
  DELETED = 'DELETED',
}

export enum ForTypes {
  ORG = 'ORG',
  APP = 'APP',
  CAMPAIGN = 'CAMPAIGN',
  ADGROUP = 'ADGROUP',
  KEYWORD = 'KEYWORD',
  SEARCHTERM = 'SEARCHTERM',
}

export enum FromTypes {
  ORG = 'ORG',
  APP = 'APP',
  CAMPAIGN = 'CAMPAIGN',
  ADGROUP = 'ADGROUP',
  KEYWORD = 'KEYWORD',
}

export enum AIOperations {
  SET_TO = 'SET_TO',
  DECREASE_BY = 'DECREASE_BY',
  INCREASE_BY = 'INCREASE_BY',
  SET_TO_AVG_CPT = 'SET_TO_AVG_CPT',
  SET_TO_AVG_CPA = 'SET_TO_AVG_CPA',
}

export enum AIOperators {
  VALUE = 'VALUE',
  PERCENTAGE = 'PERCENTAGE',
}

export enum AIMatchTypes {
  BROAD = 'BROAD',
  EXACT = 'EXACT',
}

export enum ACFields {
  INSTALLS = 'INSTALLS',
  IMPRESSIONS = 'IMPRESSIONS',
  TAPS = 'TAPS',
  // TODO spend, cpa & cpt are nested
  SPEND = 'SPEND',
  TTR = 'TTR',
  CPA = 'CPA',
  CPT = 'CPT',
  CONVERSION_RATE = 'CONVERSION_RATE',
  RE_DOWNLOADS = 'RE_DOWNLOADS',
  NEW_DOWNLOADS = 'NEW_DOWNLOADS',
  LAT_OFF_INSTALLS = 'LAT_OFF_INSTALLS',
  LAT_ON_INSTALLS = 'LAT_ON_INSTALLS',
}

export enum ARExcludeDays {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN'
}

export enum AITypes {
  EMAIL_NOTIF = 'EMAIL_NOTIF',
  SLACK_NOTIF = 'SLACK_NOTIF',
  PAUSE = 'PAUSE',
  CHANGE_DAILY_CAP = 'CHANGE_DAILY_CAP',
  CHANGE_BUDGET = 'CHANGE_BUDGET',
  CHANGE_BID = 'CHANGE_BID',
  ADD_AS_KEYWORD = 'ADD_AS_KEYWORD',
  ADD_AS_NEG_KEYWORD = 'ADD_AS_NEG_KEYWORD',
}

export enum AIAddTo {
  ALL_SELECTED_ADGROUPS = 'ALL_SELECTED_ADGROUPS',
  ALL_SELECTED_CAMPAIGNS = 'ALL_SELECTED_CAMPAIGNS',
  ALL_ADGROUPS_SELECTED_CAMPAIGNS = 'ALL_ADGROUPS_SELECTED_CAMPAIGNS',
}

export enum AICPTBidTypes {
  SET_TO_CUSTOM = 'SET_TO_CUSTOM',
  ADGROUP_DEFAULT_BID = 'ADGROUP_DEFAULT_BID',
}

export enum CITimeRanges {
  CUSTOM_HOUR = 'CUSTOM_HOUR',
  CUSTOM_FRAME = 'CUSTOM_FRAME',
  LAST_1_HOUR = 'LAST_1_HOUR',
  LAST_1_DAY = 'LAST_1_DAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_MONTH = 'THIS_MONTH',
}

export enum CIAndORTypes {
  AND = 'AND',
  OR = 'OR',
}

export enum CIOperations {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  IN_RANGE = 'IN_RANGE',
  NOT_IN_RANGE = 'NOT_IN_RANGE',
}

export enum CIOperators {
  PERCENTAGE_OF_BUDGET = 'PERCENTAGE_OF_BUDGET',
  DOLLAR_AMOUNT = 'DOLLAR_AMOUNT',
}

export enum LogStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
}

