var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.DecisionAlert,
            callback: function($$v) {
              _vm.DecisionAlert = $$v
            },
            expression: "DecisionAlert"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline grey lighten-2" }, [
                _vm._v(" " + _vm._s(_vm.DecisionAlertTitle) + " ")
              ]),
              _c("v-card-text", [
                _vm._v(" " + _vm._s(_vm.DecisionAlertMessage) + " ")
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.emitDecisionAlertCancelClicked }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("decisionAlert.cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.emitDecisionAlertOkClicked }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("decisionAlert.ok")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }