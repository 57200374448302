var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1200px" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-snackbar",
        {
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", text: "" },
                      on: {
                        click: function($event) {
                          _vm.onlyOneAdgroupSnack = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("selectKeywordsAR.close")) + " "
                      )
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.onlyOneAdgroupSnack,
            callback: function($$v) {
              _vm.onlyOneAdgroupSnack = $$v
            },
            expression: "onlyOneAdgroupSnack"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("selectKeywordsAR.keepOneAdGroup")) + " ")]
      ),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline grey lighten-2 d-flex justify-space-between px-5 py-3 mb-5"
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("selectKeywordsAR.chooseKeywords")))
              ]),
              _c("v-icon", { on: { click: _vm.close } }, [_vm._v("mdi-close")])
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-7 pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "grey",
                    depressed: "",
                    outlined: ""
                  },
                  on: { click: _vm.close }
                },
                [_vm._v(" " + _vm._s(_vm.$t("selectKeywordsAR.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "success",
                    depressed: "",
                    disabled: !_vm.totalSelectedKeywords
                  },
                  on: { click: _vm.save }
                },
                [_vm._v(" " + _vm._s(_vm.$t("selectKeywordsAR.apply")) + " ")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-5" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    {
                      staticClass: "adgroup",
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "3" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center d-flex align-center justify-space-between"
                                },
                                [
                                  _c("h5", [
                                    _vm._v(
                                      _vm._s(_vm.totalCampaigns) +
                                        " " +
                                        _vm._s(
                                          _vm.$t("selectKeywordsAR.campaigns")
                                        )
                                    )
                                  ]),
                                  this.panel.length == 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "info--text",
                                          attrs: { depressed: "", text: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.expandAll()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-arrow-collapse-down ")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "selectKeywordsAR.expandAll"
                                                )
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  this.panel.length > 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "info--text",
                                          attrs: { depressed: "", text: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.collapseAll()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-arrow-collapse-up")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "selectKeywordsAR.collapseAll"
                                                )
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "3" }
                            },
                            [
                              _c("div", { staticClass: "search-list" }, [
                                _c("h5", { staticClass: "pt-2" }, [
                                  _vm._v(
                                    _vm._s(_vm.totalListedGroups) +
                                      " " +
                                      _vm._s(
                                        _vm.$t("selectKeywordsAR.adGroups")
                                      )
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "3" }
                            },
                            [
                              _c("div", { staticClass: "search-list" }, [
                                _c("h5", { staticClass: "pt-2" }, [
                                  _vm._v(
                                    _vm._s(_vm.totalListedKeywords) +
                                      " " +
                                      _vm._s(
                                        _vm.$t("selectKeywordsAR.keywords")
                                      )
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "3" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "selected-groups-col d-flex align-center justify-space-between mt-1"
                                },
                                [
                                  _c("h5", [
                                    _vm._v(
                                      _vm._s(_vm.totalSelectedKeywords) +
                                        " " +
                                        _vm._s(
                                          _vm.$t(
                                            "selectKeywordsAR.keywordSelected"
                                          )
                                        )
                                    )
                                  ]),
                                  _vm.totalSelectedKeywords
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            depressed: "",
                                            text: "",
                                            small: "",
                                            color: "info"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clearAll()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-window-close")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "selectKeywordsAR.clearAll"
                                                )
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", sm: "3" }
                            },
                            [
                              [
                                _c(
                                  "v-expansion-panels",
                                  {
                                    attrs: { multiple: "" },
                                    model: {
                                      value: _vm.panel,
                                      callback: function($$v) {
                                        _vm.panel = $$v
                                      },
                                      expression: "panel"
                                    }
                                  },
                                  _vm._l(_vm.appsListWithCampaigns, function(
                                    app,
                                    i
                                  ) {
                                    return _c(
                                      "v-expansion-panel",
                                      { key: i },
                                      [
                                        _c(
                                          "v-expansion-panel-header",
                                          [
                                            _c("v-img", {
                                              staticClass: "mr-3",
                                              attrs: {
                                                "max-height": "22",
                                                "max-width": "22"
                                              }
                                            }),
                                            _vm._v(
                                              " " + _vm._s(app.appName) + " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "no float-right text-right"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(app.campaigns.length)
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-expansion-panel-content",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "mx-auto",
                                                staticStyle: {
                                                  overflow: "scroll",
                                                  height: "300px"
                                                },
                                                attrs: {
                                                  outlined: "",
                                                  "max-width": "300",
                                                  tile: ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c(
                                                      "v-list-item-group",
                                                      {
                                                        attrs: {
                                                          color: "primary"
                                                        }
                                                      },
                                                      _vm._l(
                                                        app.campaigns,
                                                        function(campaign) {
                                                          return _c(
                                                            "v-list-item",
                                                            {
                                                              key: campaign.id,
                                                              attrs: {
                                                                selectable: true
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.listAdgroups(
                                                                    campaign
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      domProps: {
                                                                        textContent: _vm._s(
                                                                          campaign.name
                                                                        )
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ]
                            ],
                            2
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", sm: "3" }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mx-auto",
                                  staticStyle: {
                                    overflow: "scroll",
                                    height: "300px"
                                  },
                                  attrs: {
                                    outlined: "",
                                    "max-width": "300",
                                    tile: ""
                                  }
                                },
                                [
                                  _vm.selectedCampaign
                                    ? _c(
                                        "v-list",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-list-item-group",
                                            { attrs: { color: "primary" } },
                                            _vm._l(
                                              _vm.selectedCampaign.adgroups,
                                              function(adgroup) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: adgroup.id,
                                                    attrs: { selectable: true },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.listKeywords(
                                                          adgroup
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                adgroup.name
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", sm: "3" }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "mx-auto d-flex pa-4 virtual-scroll",
                                  attrs: {
                                    outlined: "",
                                    "max-width": "300",
                                    tile: ""
                                  }
                                },
                                [
                                  _vm.selectedAdgroup
                                    ? _c(
                                        "div",
                                        { staticClass: "boxes" },
                                        _vm._l(
                                          _vm.selectedAdgroup.keywords,
                                          function(keyword) {
                                            return _c("v-checkbox", {
                                              key: keyword.id,
                                              staticClass: "mt-0",
                                              attrs: {
                                                label: keyword.text,
                                                color: "success",
                                                "hide-details": ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.oncheck(keyword)
                                                }
                                              },
                                              model: {
                                                value: keyword.selected,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    keyword,
                                                    "selected",
                                                    $$v
                                                  )
                                                },
                                                expression: "keyword.selected"
                                              }
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", sm: "3" }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "mx-auto d-flex flex-column pa-4 virtual-scroll",
                                  attrs: {
                                    outlined: "",
                                    "max-width": "300",
                                    tile: ""
                                  }
                                },
                                [
                                  _vm._l(_vm.appsListWithCampaigns, function(
                                    app
                                  ) {
                                    return [
                                      _vm._l(app.campaigns, function(campaign) {
                                        return [
                                          _vm._l(campaign.adgroups, function(
                                            group
                                          ) {
                                            return [
                                              _vm._l(group.keywords, function(
                                                keyword
                                              ) {
                                                return [
                                                  keyword.selected
                                                    ? _c("label", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              keyword.text
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  keyword.selected
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            width: "32",
                                                            depressed: "",
                                                            "x-small": "",
                                                            fab: "",
                                                            text: "",
                                                            color: "error"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deleteKeyword(
                                                                keyword
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-delete-outline "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              })
                                            ]
                                          })
                                        ]
                                      })
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }